import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'api/quickbooks';

// CashFlow
export const cashFlow = async params => {
    const endPointComplement = 'cashflow';
    try {
      const { data } = await api.post(`/${endPoint}/${endPointComplement}`, params);
      return data;
    } catch (error) {
      errorHandler(error);
      return { Rows: { Row: [] } }; 
    }
  };

// ProfitAndLoss
export const profitAndLoss = async params => {
    const endPointComplement = 'profitandloss';
    try {
      const { data } = await api.post(`/${endPoint}/${endPointComplement}`, params);
      return data;
    } catch (error) {
      errorHandler(error);
      return { Rows: { Row: [] } }; 
    }
  };
