import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import SubContractorCloseOutForm from './form';
import Filter from './filter';
import { downloadFile } from '~/services/hooks/files';
import PageTitle from '~/components/PageTitle';
import DefaultLayout from '~/pages/_layouts/full';
import { BreadcrumbCustom } from '~/styles/global';
import { useSelector } from 'react-redux';
import { paginationSubVendors } from '~/services/hooks/subVendors';
import { paginationCloseOutFiles, deleteCloseOutFile } from '~/services/hooks/closeoutFiles';
import { getProjectStatusColor } from '~/Utils';
import moment from 'moment';

export default function SubContractorCloseOuts() {
  const profile = useSelector(state => state.user.profile);
  const token = useSelector(state => state.auth.token);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState('description');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [filters, setFilters] = useState([]);
  const [subVendor, setSubVendor] = useState({});

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationCloseOutFiles({
      page: page - 1,
      sortBy,
      sortDirection,
      filters: [
        ...filters,
        {
          columnJoin: "subVendor",
          columnSecondJoin: "user",
          field: "id",
          value: profile.id,
          restriction: "EQUAL"
        }
      ],
    });
    setTableData(data.content);
    const metaData = {
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber,
      size: data.size,
      totalElements: data.totalElements,
      totalPages: data.totalPages,
    };
    setMeta(metaData);
    setLoading(false);
  };

  useEffect(() => {
    if (profile?.id > 0) {
      paginationSubVendors({
        page: 0,
        maxSize: true,
        filters: [
          {
            columnJoin: 'user',
            field: 'id',
            value: profile.id,
            restriction: 'EQUAL',
          },
        ],
      }).then(response => {
        setSubVendor(response.content[0]);
      });
    }
  }, [profile]);

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy, sortDirection]);

  const handleDelete = async id => {
    await deleteCloseOutFile(id);
    fetchData(1);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const onUpdateItem = item => {
    const index = tableData.findIndex(i => i.id === item.id);
    if (index > -1) {
      const newData = [...tableData];
      newData[index] = item;
      setTableData(newData);
    }
  };

  const style = {
    breadcrumb: { margin: '16px 0' },
    pageTitle: { marginRight: 'auto', width: '100%' },
    sent: { backgroundColor: '#52c41a' },
    notSent: { backgroundColor: '#d9d9d9', color: 'gray' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
    btnDownload: { padding: '0 5px' },
    btnEdit: { padding: '0 5px' },
  };

  const tableColumns = [
    {
      title: t('screens:closeout.subcontractor.id'),
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      sorter: true,
      width: 80,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:closeout.subcontractor.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('screens:closeout.subcontractor.projectDescription'),
      dataIndex: 'project',
      key: 'project.description',
      align: 'left',
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
      render: project => {
        return project.description;
      },
    },
    {
      title: t('screens:closeout.subcontractor.projectStatus'),
      dataIndex: 'project',
      key: 'project.status',
      align: 'left',
      sorter: true,
      width: 150,
      ellipsis: {
        showTitle: true,
      },
      render: project =>(
        <Tag color={getProjectStatusColor(project.status.toLowerCase())}>
          {t('screens:projects.data.status.' + project.status.toLowerCase()).toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t('screens:closeout.subcontractor.createDate'),
      dataIndex: 'audit',
      key: 'audit.createDate',
      align: 'left',
      width: 150,
      sorter: true,
      ellipsis: { showTitle: true },
      render: audit => <>{moment(audit?.createdDate).format('YYYY/MM/DD')}</>,
    },
    {
      title: t('screens:closeout.subcontractor.actions'),
      key: 'actions',
      align: 'center',
      width: 200,
      ellipsis: {
        showTitle: true,
      },
      className: 'table-action',
      render: record => (
        <TableActions>
          <Button onClick={async () => await downloadFile(record.archive)} title={t('messages:download')}>
            <DownloadOutlined />
          </Button>
          <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
            <EditOutlined />
          </Button>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Button title={t('messages:delete')}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </TableActions>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === 'ascend' ? '' : 'DESC';
    if (sorter.field != sortBy || sortOrder != sortDirection) {
      setSortBy(sorter.field);
      setSortDirection(sortOrder);
      fetchData(1);
    }
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:contracts')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <PageTitle title={t('menus:closeOuts')} />
      <Filter isLoading={loading} setFilters={setFilters} projects={subVendor?.projectAccess} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:closeout.subcontractor.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={[...tableData]}
          onChange={handleTableChange}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
        <SubContractorCloseOutForm
          subVendor={subVendor}
          selectedRecord={selectedRecord}
          visible={showForm}
          onClose={() => setShowForm(false)}
          onInsertItem={item => {
            setTableData([...tableData, item]);
          }}
          onUpdateItem={item => onUpdateItem(item)}
        />
      </Box>
    </DefaultLayout>
  );
}
