import React, { useEffect, useState } from 'react';
import Icon, {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SwapOutlined,
  TeamOutlined,
  MailOutlined,
  AuditOutlined,
  UploadOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  Col,
  Table as TableAntd,
  Button as ButtonAntd,
  Tooltip,
  Tag,
  InputNumber,
  Badge,
  Select,
  Space,
  Popconfirm,
  Typography,
  message,
  Modal,
  Checkbox,
  Upload,
} from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Row from '~/components/Row';
import { Table } from '~/components/Table';
import Button from '~/components/Button';
import { formatPrice } from '~/Utils';
import EstimateForm from './form';
import moment from 'moment';
import { paginationEstimates } from '~/services/hooks/estimates';
import { paginationAreas } from '~/services/hooks/areas';
import { paginationLaborTypes } from '~/services/hooks/laborTypes';
import { paginationSubVendors } from '~/services/hooks/subVendors';
import {
  createEstimateSubVendor,
  updateEstimateSubVendor,
  deleteEstimateSubVendor,
  findAllEstimateSubVendors,
} from '~/services/hooks/estimateSubVendors';
import { updateEstimate, createEstimate, sendEstimateToSubVendor } from '~/services/hooks/estimates';
import { paginationEstimateDescriptions } from '~/services/hooks/estimateDescriptions';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import ModalProposal from './modal';
import errorHandler from '~/Utils/errorHandler';
import axios from 'axios';

const { Text } = Typography;
const style = {
  btnNew: {
    col: { marginLeft: 'auto', paddingBottom: '20px' },
    btn: { marginLeft: 'auto' },
  },
  header: {
    col: { paddingBottom: '20px', paddingTop: '10px' },
  },
  footer: {
    col: { paddingTop: '20px' },
  },
};

export default function Estimates({ project, customer }) {
  const token = useSelector(state => state.auth.token);
  const estimateInitialValues = {
    id: null,
    sent: null,
    expireTime: 0,
    project: project,
    items: [],
  };
  const projectId = project.id;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [completeEstimate, setCompleteEstimate] = useState([]);
  const [completeEstimateByArea, setCompleteEstimateByArea] = useState([]);
  const [estimateData, setEstimateData] = useState([estimateInitialValues]);
  const [allSubVendors, setAllSubVendors] = useState([]);
  const [selectedEstimateSubVendors, setSelectedEstimateSubVendors] = useState([]);
  const [estimateDescriptions, setEstimateDescriptions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [laborTypes, setLaborTypes] = useState([]);
  const [visible, setVisible] = useState(false);
  const [viewByService, setViewByService] = useState(false); // false = view by area, true = view by service
  const [visibleSubVendors, setVisibleSubVendors] = useState(false);
  const [selectedLaborType, setSelectedLaborType] = useState(null);
  const user = useSelector(state => state.user.profile);
  const [approvedBy, setApprovedBy] = useState(null);
  const [showProposalModal, setProposalModal] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const data = await paginationEstimates({
      page: 0,
      maxSize: true,
      filters: [{ columnJoin: 'project', field: 'id', value: projectId, restriction: 'EQUAL' }],
    });
    if (data.numberOfElements === 0) {
      const newEstimate = await createEstimate(estimateInitialValues);
      setEstimateData(newEstimate);
    } else {
      setEstimateData(data.content[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();

    paginationSubVendors({
      page: 0,
      maxSize: true,
      filters: [{ field: 'type', value: 'SUBCONTRACTOR', restriction: 'EQUAL' }],
    }).then(data => {
      setAllSubVendors(data.content);
    });

    paginationAreas({ page: 0, sortBy: 'name', maxSize: true, filters: [] }).then(data => {
      setAreas(data.content);
    });

    paginationLaborTypes({ page: 0, sortBy: 'name', maxSize: true, filters: [] }).then(data => {
      setLaborTypes(data.content);
    });

    paginationEstimateDescriptions({ page: 0, sortBy: 'description', maxSize: true, filters: [] }).then(data => {
      setEstimateDescriptions(data.content);
    });
  }, []);

  useEffect(() => {
    if (estimateData.id) {
      findAllEstimateSubVendors([
        {
          columnJoin: 'estimateItem',
          columnSecondJoin: 'estimate',
          field: 'id',
          value: estimateData.id,
          restriction: 'EQUAL',
        },
      ]).then(data => {
        setSelectedEstimateSubVendors(data);
      });
      setApprovedBy(estimateData.approvedBy);
    }

  }, [estimateData]);

  const mountEstimate = () => {
    const complete = [];
    laborTypes.forEach(element => {
      element.laborTypeId = element.id;
      element.name = element.name;
      element.subVendors = selectedEstimateSubVendors
        .filter(sub => sub.estimateItem?.laborType?.id === element.id)
        .map(sub => sub.subVendor);
      element.subVendors = element.subVendors.filter(
        (sub, index, self) => index === self.findIndex(t => t.id === sub.id)
      );
      element.items = estimateData.items.filter(item => item?.laborType?.id === element.id);
      complete.push(element);
    });
    const completeFiltered = complete.filter(element => element.items.length > 0);
    setCompleteEstimate(completeFiltered);
  };

  const mountEstimateByArea = () => {
    const complete = [];
    areas.forEach(element => {
      element.area_id = element.id;
      element.name = element.name;
      element.items = estimateData.items.filter(item => item.area.id === element.id);
      complete.push(element);
    });
    const completeFiltered = complete.filter(element => element.items.length > 0);
    setCompleteEstimateByArea(completeFiltered);
  };

  useEffect(() => {
    if (estimateData.items !== undefined) {
      if (
        estimateData.items.length > 0 &&
        laborTypes.length > 0 &&
        areas.length > 0 &&
        selectedEstimateSubVendors.length >= 0
      ) {
        mountEstimate();
        mountEstimateByArea();
      }
    }
  }, [estimateData.items, laborTypes, areas, selectedEstimateSubVendors]);

  const handleDownloadExcel = async () => {
    axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/estimates/generateEstimatingExcel`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'estimating.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  }
  
  const handleUploadExcel = async event => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append('file', event);
      formData.append('estimateId', estimateData.id);
      const { data } = await api.post('/estimates/readEstimatingExcel', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (data !== null) {
        setEstimateData(data);
      }

      message.success(t('messages:itemsSuccessImported'));
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
    setLoading(false);
  };

  const beforeUploadExcel = file => {
    const isValid = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isValid) {
      message.error('You can only import file of type: .xlsx');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('The file must be less than 10MB!');
    }
    return isValid && isLt2M;
  };

  const handleNew = () => {
    setSelectedRecord(null);
    setVisible(true);
  };

  const handleEdit = record => {
    setSelectedRecord(record);
    setVisible(true);
  };

  const handleDeleteLaborGroup = async id => {
    // find all items with this labor type id and delete it in estimateSubVendors table
    const estimateSubVendors = selectedEstimateSubVendors.filter(sub => sub.estimateItem.laborType.id === id);
    estimateSubVendors.forEach(async sub => {
      await deleteEstimateSubVendor(sub.id);
    });
    // update estimateData items without the deleted items
    const newEstimateItems = estimateData.items.filter(item => item.laborType.id !== id);
    await updateEstimate({ ...estimateData, items: newEstimateItems });
    setEstimateData({ ...estimateData, items: newEstimateItems });
  };

  const handleDeleteAreaGroup = async id => {
    // find all items with this area id and delete it in estimateSubVendors table
    const estimateSubVendors = selectedEstimateSubVendors.filter(sub => sub.estimateItem.area.id === id);
    estimateSubVendors.forEach(async sub => {
      await deleteEstimateSubVendor(sub.id);
    });
    // update estimateData items without the deleted items
    const newEstimateItems = estimateData.items.filter(item => item.area.id !== id);
    await updateEstimate({ ...estimateData, items: newEstimateItems });
    setEstimateData({ ...estimateData, items: newEstimateItems });
    await fetchData();
  };

  const handleDeleteItem = async idItem => {
    // verify if exits a record with this id in estimateSubVendors table and delete it
    const estimateSubVendor = selectedEstimateSubVendors.find(sub => sub.estimateItem.id === idItem);
    if (estimateSubVendor) {
      await deleteEstimateSubVendor(estimateSubVendor.id);
    }
    // update estimateData items without the deleted item
    await updateEstimate({
      ...estimateData,
      items: estimateData.items.filter(item => item.id !== idItem),
    });
    await fetchData();
  };

  const handleAddSubVendors = laborTypeId => {
    setVisibleSubVendors(true);
    setSelectedLaborType(laborTypeId);
  };

  const handleCancelSubVendors = () => {
    setVisibleSubVendors(false);
  };

  const tagRender = props => {
    const limitDays = 30;
    const { label, value, closable, onClose } = props;
    const subVendor = allSubVendors.find(sub => sub.id === value);
    const liabilityInsuranceExpirationDate = subVendor?.liabilityInsurence?.expiration_date;
    const workersCompensationExpirationDate = subVendor?.workersCompensationInsurence?.expiration_date;

    // verify if subvendor liabilityInsurance expiration_date is less than limit days
    const isExpiredLiabilityInsurance = liabilityInsuranceExpirationDate
      ? moment(liabilityInsuranceExpirationDate).diff(moment(), 'days') < limitDays
      : true;

    // verify if subvendor workersCompensation expiration_date is less than limit days
    const isExpiredWorkersCompensation = workersCompensationExpirationDate
      ? moment(workersCompensationExpirationDate).diff(moment(), 'days') < limitDays
      : false;

    const color = isExpiredLiabilityInsurance || isExpiredWorkersCompensation ? 'red' : 'default';
    let title = null;

    if (isExpiredLiabilityInsurance || isExpiredWorkersCompensation) {
      title = `Liability Insurence expiration date: ${liabilityInsuranceExpirationDate}\nWorkers Compensation expiration date: ${workersCompensationExpirationDate}`;
    }

    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        key={value}
        title={title}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleAddEstimateSubVendor = async estimateSubVendor => {

    const exists = selectedEstimateSubVendors.find(e => e.id === estimateSubVendor.id);

    if (exists) {
      estimateSubVendor.id = exists.id;
      estimateSubVendor.value = exists.value;
      estimateSubVendor.dueDate = exists.dueDate;
      estimateSubVendor.note = exists.note;
      estimateSubVendor.responserName = exists.responserName;
      estimateSubVendor.responserJobTitle = exists.responserJobTitle;
      estimateSubVendor.status =
        estimateSubVendor.status !== exists.status && exists.status === 'NEW'
          ? estimateSubVendor.status
          : exists.status;
      estimateSubVendor.winner = exists.winner;
      estimateSubVendor.materialValue = exists.materialValue;
      estimateSubVendor.laborValue = exists.laborValue;
      await updateEstimateSubVendor(estimateSubVendor);
    } else {
      await createEstimateSubVendor(estimateSubVendor);
    }
  };

  const handleDeleteEstimateSubVendor = () => {
    const allItemsIds = [];
    const allSubVendorsIds = [];
    completeEstimate.map(estimateGroup => {
      const estimateItems = estimateGroup.items;
      estimateItems.forEach(item => {
        allItemsIds.push(item.id);
      });
      const estimateSubvendors = estimateGroup.subVendors;
      estimateSubvendors.forEach(subVendor => {
        allSubVendorsIds.push(subVendor.id);
      });
    });
    // filter selectedEstimateSubVendors that are not have estimateItem.id in allItemsIds and not have subvendor.id in allSubVendorsIds
    const estimateSubVendors = selectedEstimateSubVendors.filter(
      sub => !(allItemsIds.includes(sub.estimateItem.id) && allSubVendorsIds.includes(sub.subVendor.id))
    );
    // delete estimateSubVendors
    estimateSubVendors.forEach(async subVendor => {
      await deleteEstimateSubVendor(subVendor.id);
    });
  };

  const handleSaveEstimateSubvendors = status => {
    completeEstimate.map(estimateGroup => {
      const estimateItems = estimateGroup.items;
      const estimateSubvendors = estimateGroup.subVendors;
      estimateSubvendors.forEach(subVendor => {
        estimateItems.forEach(estimateItem => {
          const estimateSubvendor = {
            id: null,
            subVendor: subVendor,
            estimateItem: estimateItem,
            value: 0.0,
            dueDate: 0,
            note: '',
            responserName: '',
            responserJobTitle: '',
            status: status ? status : 'NEW',
            winner: false,
            materialValue: 0.0,
            laborValue: 0.0,
            quantity: estimateItem.copyQtdToVendor ? estimateItem.quantity : 0,
          };
          handleAddEstimateSubVendor(estimateSubvendor);
        });
      });
    });
    handleDeleteEstimateSubVendor();
  };

  const handleSave = async (values, { setSubmitting }) => {
    setSubmitting(true);
    // handleSaveEstimateSubvendors();
    await updateEstimate(values);
    fetchData();
    setSubmitting(false);
  };

  const handleApprove = async () => {
    await updateEstimate({ ...estimateData, approvedBy: user });
    // fetch data again to update estimateData
    fetchData();
    message.info(t('messages:estimates.approved'));
  };

  const handleUnApprove = async () => {
    await updateEstimate({ ...estimateData, approvedBy: null });
    // fetch data again to update estimateData
    fetchData();
    message.info(t('messages:estimates.unapproved'));
  };

  const handleSaveAndSendSubcontractors = async values => {
    values.sent = moment().format('YYYY-MM-DD HH:mm:ss.000');
    await updateEstimate(values);
    handleSaveEstimateSubvendors('SENT');
    await sendEstimateToSubVendor(estimateData.id);
  };

  const handleSaveAndSendCustomers = async values => {
    await updateEstimate(values);
    fetchData();
    setProposalModal(true);
  };

  const handleSendClient = async ({ model, generalConditions, overheadAndProfit, warehousing, notes, otherItems }) => {
    setLoading(true);

    try {
      const estimateDTO = {
        estimateId: estimateData.id,
        model: model,
        generalConditions: generalConditions,
        overheadAndProfit: overheadAndProfit,
        warehousing: warehousing,
        notes: notes,
        otherItems: otherItems,
      };

      await api.post(`/mail/sendEstimateToClient`, estimateDTO);
      setProposalModal(false);
      message.success(t('messages:estimates.sendToCustomer'));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  }

  const tableColumns = [
    {
      title: (
        <Tooltip title="Change View">
          <ButtonAntd type="link" ghost onClick={() => setViewByService(false)}>
            <span style={{ marginLeft: '5px', fontWeight: 'bold', color: '#0f4c81' }}>
              {t('screens:estimate.laborGroup')}
            </span>
            <SwapOutlined />
          </ButtonAntd>
        </Tooltip>
      ),
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      },
      align: 'center',
      width: '20%',
    },
    {
      title: t('screens:estimate.subVendors'),
      key: 'subVendors',
      ellipsis: {
        showTitle: false,
      },
      align: 'center',
      width: '80%',
      render: record => (
        <Select
          disabled={approvedBy !== null}
          mode="multiple"
          showArrow
          tagRender={tagRender}
          style={{ width: '100%' }}
          value={record?.subVendors?.map(subVendor => subVendor?.id) || []}
          options={allSubVendors
            .filter(subVendor => subVendor.laborTypes.find(laborType => laborType.id === record.laborTypeId))
            .map(subVendor => ({
              value: subVendor.id,
              label: `${subVendor.id} - ${subVendor.companyName}`,
            }))}
          onChange={newValue => {
            const newCompleteEstimate = completeEstimate.map(estimate => {
              if (estimate.id === record.id) {
                estimate.subVendors = newValue.map(value => {
                  const subVendor = allSubVendors.find(subVendor => subVendor.id === value);
                  return subVendor;
                });
              }
              return estimate;
            });
            setCompleteEstimate(newCompleteEstimate);
            handleSaveEstimateSubvendors();
          }}
          placeholder="Select Subcontractors..."
          maxTagCount="responsive"
        />
      ),
    },
    {
      title: t('screens:estimate.data.actions'),
      key: 'actions',
      align: 'center',
      width: '90px',
      render: record => (
        <Popconfirm
          icon={false}
          title={t('messages:confirmDelete')}
          onConfirm={() => handleDeleteLaborGroup(record.id)}
          okText={t('messages:yes')}
          cancelText={t('messages:no')}
        >
          <Tooltip title={t('screens:estimate.deleteGroup')} placement="bottom">
            <ButtonAntd type="link" icon={<DeleteOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
  ].filter(column => {
    if (approvedBy !== null && column.key === 'actions') {
      return false;
    }
    return true;
  });

  const tableColumnsByArea = [
    {
      title: (
        <Tooltip title="Change View">
          <ButtonAntd type="link" onClick={() => setViewByService(true)}>
            <span style={{ marginLeft: '5px', fontWeight: 'bold', color: '#0f4c81' }}>
              {t('screens:estimate.area')}
            </span>
            <SwapOutlined />
          </ButtonAntd>
        </Tooltip>
      ),
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      },
      align: 'center',
      width: '20%',
    },
    {
      // title: t('screens:estimate.subVendors'),
      key: 'subVendors',
      ellipsis: {
        showTitle: false,
      },
      align: 'center',
      width: '80%',
      render: record => <></>,
    },
    {
      title: t('screens:estimate.data.actions'),
      key: 'actions',
      align: 'center',
      width: '90px',
      render: record => (
        <Popconfirm
          icon={false}
          title={t('messages:confirmDelete')}
          onConfirm={() => handleDeleteAreaGroup(record.id)}
          okText={t('messages:yes')}
          cancelText={t('messages:no')}
        >
          <Tooltip title={t('screens:estimate.deleteGroup')} placement="bottom">
            <ButtonAntd type="link" icon={<DeleteOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
  ].filter(column => {
    if (approvedBy !== null && column.key === 'actions') {
      return false;
    }
    return true;
  });

  const tableColumnsDetails = [
    {
      title: t('screens:estimate.id'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: {
        showTitle: false,
      },
      align: 'center',
      width: '90px',
    },
    {
      title: t('screens:estimate.description'),
      key: 'estimateDescription',
      sorter: (a, b) => a.estimateDescription.description.length - b.estimateDescription.description.length,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      ellipsis: {
        showTitle: false,
      },
      render: record => <>{record.estimateDescription.description}</>,
    },
    {
      title: t('screens:estimate.area'),
      key: 'area.name',
      sorter: (a, b) => a.area.name.length - b.area.name.length,
      ellipsis: {
        showTitle: false,
      },
      render: record => <>{record.area.name}</>,
    },
    {
      title: 'aqui', // t('screens:estimate.laborGroup'),
      key: 'laborType.name',
      sorter: (a, b) => a.laborType.name.length - b.laborType.name.length,
      ellipsis: {
        showTitle: false,
      },
      render: record => {
        // show subvendors count in labor group
        const subVendorsNumber = completeEstimate.find(labor => labor.id === record.laborType.id).subVendors.length;
        return (
          <>
            <Tooltip title="Manage service type subcontractors" placement="bottom">
            <Badge
                count={subVendorsNumber > 0 ? subVendorsNumber : 0}
                offset={[-5, 10]}
                color="#0093DD"
                showZero={false}
                size="small"
                overflowCount={99}
              >
                <ButtonAntd
                  disabled={approvedBy !== null}
                  type="link"
                  icon={<TeamOutlined style={{ color: '#0093DD' }} />}
                  onClick={() => handleAddSubVendors(record.laborType.id)}
                />
              </Badge>
              <span style={{ marginLeft: '8px' }}>{record.laborType.name}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: t('screens:estimate.unit'),
      key: 'estimateDescription.unit',
      ellipsis: {
        showTitle: false,
      },
      width: '80px',
      render: record => <>{record.estimateDescription.unit}</>,
    },
    {
      title: t('screens:estimate.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      ellipsis: {
        showTitle: false,
      },
      width: '80px',
    },
    {
      title: t('screens:estimate.copyQtdToVendor'),
      dataIndex: 'copyQtdToVendor',
      key: 'copyQtdToVendor',
      ellipsis: {
        showTitle: false,
      },
      width: '80px',
      render: copyQtdToVendor => <Checkbox checked={copyQtdToVendor} disabled={true} />,
    },
    {
      title: t('screens:estimate.materialValue'),
      dataIndex: 'materialValue',
      key: 'materialValue',
      sorter: (a, b) => a.materialValue - b.materialValue,
      ellipsis: {
        showTitle: false,
      },
      width: '100px',
      render: materialValue => <>{formatPrice(materialValue)}</>,
    },
    {
      title: t('screens:estimate.laborValue'),
      dataIndex: 'laborValue',
      key: 'laborValue',
      sorter: (a, b) => a.laborValue - b.laborValue,
      ellipsis: {
        showTitle: false,
      },
      width: '100px',
      render: laborValue => <>{formatPrice(parseFloat(laborValue))}</>,
    },
    {
      title: t('screens:estimate.totalMaterial'),
      key: 'total_material',
      sorter: (a, b) => parseFloat(a.materialValue * a.quantity) - parseFloat(b.materialValue * b.quantity),
      ellipsis: {
        showTitle: false,
      },
      width: '100px',
      render: record => <>{formatPrice(parseFloat(record.materialValue * record.quantity))}</>,
    },
    {
      title: t('screens:estimate.totalLabor'),
      key: 'total_labor',
      sorter: (a, b) => parseFloat(a.laborValue * a.quantity) - parseFloat(b.laborValue * b.quantity),
      ellipsis: {
        showTitle: false,
      },
      width: '100px',
      render: record => <>{formatPrice(parseFloat(record.laborValue * record.quantity))}</>,
    },
    {
      title: t('screens:estimate.totalMaterialAndLabor'),
      key: 'total_material_and_labor',
      sorter: (a, b) =>
        parseFloat((a.laborValue + a.materialValue) * a.quantity) -
        parseFloat((b.laborValue + b.materialValue) * b.quantity),
      ellipsis: {
        showTitle: false,
      },
      width: '100px',
      render: record => <>{formatPrice(parseFloat((record.laborValue + record.materialValue) * record.quantity))}</>,
    },
    {
      title: t('screens:estimate.data.actions'),
      key: 'actions',
      align: 'center',
      width: '90px',
      render: record => (
        <Space>
          <Tooltip title={t('screens:estimate.editItem')}>
            <ButtonAntd type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          </Tooltip>
          <Popconfirm
            icon={false}
            title={t('messages:confirmDelete')}
            onConfirm={() => handleDeleteItem(record.id)}
            okText={t('messages:yes')}
            cancelText={t('messages:no')}
          >
            <Tooltip title={t('screens:estimate.deleteItem')} placement="bottom">
              <ButtonAntd type="link" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ].filter(column => {
    if (!viewByService && column.key === 'area.name') {
      return false;
    }
    if (viewByService && column.key === 'laborType.name') {
      return false;
    }
    if (approvedBy !== null && column.key === 'actions') {
      return false;
    }
    return true;
  });

  const estimateSchema = Yup.object().shape({
    expireTime: Yup.number()
      .min(1)
      .max(100)
      .required(),
  });

  return (
    <Box>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
        initialValues={estimateData}
        onSubmit={handleSave}
        validationSchema={estimateSchema}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <Row>
              <Col span={12} style={style.header.col}>
                <Space style={{ float: 'left' }}>
                  <>Expires in</>
                  <InputNumber
                    style={{ width: 70 }}
                    name="expireTime"
                    size="small"
                    value={values.expireTime || 0}
                    onChange={value => {
                      setFieldValue('expireTime', value);
                    }}
                    min={1}
                    max={100}
                    step={1}
                    precision={0}
                  />
                  <>business days after dispatch</>
                  {errors.expireTime && touched.expireTime && <div style={{ color: 'red' }}>({errors.expireTime})</div>}
                </Space>
              </Col>
              <Col span={12} style={style.header.col}>
                <>approvedBy: {approvedBy?.name}</>
                <Space style={{ float: 'right' }}>
                  {approvedBy === null ? (
                    // TODO: check if this is the best way to check if estimate is approved
                    <>
                    {estimateData.items === undefined || estimateData.items.length === 0 ? 
                      <><Button onClick={handleDownloadExcel}><DownloadOutlined />{'Baixar excel'}</Button>
                    
                      <Upload
                        name={'file'}
                        multiple={false}
                        showUploadList={false}
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        beforeUpload={beforeUploadExcel}
                        action={handleUploadExcel}
                      >
                        <Button><UploadOutlined />{'Importar excel'}</Button>
                      </Upload>
                      </>
                     : null}
                      <Button
                        loading={loading}
                        color="primary"
                        onClick={() => {
                          handleNew();
                        }}
                      >
                        <PlusOutlined />
                        {t('screens:estimate.btnNew')}
                      </Button></>
                  ) : (
                    <span style={{ color: 'green' }}>
                      <AuditOutlined /> This estimate was approved by {approvedBy?.name}
                    </span>
                  )}
                </Space>
              </Col>
            </Row>
            {viewByService ? (
              <Row>
                <Table
                  bordered
                  rowKey="id"
                  pagination={false}
                  loading={loading}
                  columns={tableColumns}
                  dataSource={completeEstimate}
                  expandable={{
                    expandedRowRender: record => (
                      <TableAntd
                        bordered
                        rowKey="id"
                        pagination={false}
                        loading={loading}
                        columns={tableColumnsDetails}
                        dataSource={record.items}
                        showHeader={true}
                        size="small"
                        title={() => 'Details'}
                        summary={pageData => {
                          let totalMaterial = 0;
                          let totalLabor = 0;
                          let total = 0;
                          pageData.forEach(({ quantity, materialValue, laborValue }) => {
                            totalMaterial += materialValue * quantity;
                            totalLabor += laborValue * quantity;
                            total = totalMaterial + totalLabor;
                          });
                          return (
                            <>
                              <TableAntd.Summary.Row style={{ backgroundColor: '#fffff0' }}>
                                <TableAntd.Summary.Cell index={0} colSpan={6} />
                                <TableAntd.Summary.Cell index={1}>
                                  <Text strong>Totals</Text>
                                </TableAntd.Summary.Cell>
                                <TableAntd.Summary.Cell index={2}>
                                  <Text strong>{formatPrice(totalMaterial)}</Text>
                                </TableAntd.Summary.Cell>
                                <TableAntd.Summary.Cell index={3}>
                                  <Text strong>{formatPrice(totalLabor)}</Text>
                                </TableAntd.Summary.Cell>
                                <TableAntd.Summary.Cell index={4}>
                                  <Text strong>{formatPrice(total)}</Text>
                                </TableAntd.Summary.Cell>
                              </TableAntd.Summary.Row>
                            </>
                          );
                        }}
                      />
                    ),
                    rowExpandable: record => record.name !== 'Not Expandable',
                  }}
                />
              </Row>
            ) : (
              <Row>
                <Table
                  bordered
                  rowKey="id"
                  pagination={false}
                  loading={loading}
                  columns={tableColumnsByArea}
                  dataSource={completeEstimateByArea}
                  expandable={{
                    expandedRowRender: record => (
                      <TableAntd
                        bordered
                        rowKey="id"
                        pagination={false}
                        loading={loading}
                        columns={tableColumnsDetails}
                        dataSource={record.items}
                        showHeader={true}
                        size="small"
                        title={() => 'Details'}
                        summary={pageData => {
                          let totalMaterial = 0;
                          let totalLabor = 0;
                          let total = 0;
                          pageData.forEach(({ quantity, materialValue, laborValue }) => {
                            totalMaterial += materialValue * quantity;
                            totalLabor += laborValue * quantity;
                            total = totalMaterial + totalLabor;
                          });
                          return (
                            <>
                              <TableAntd.Summary.Row style={{ backgroundColor: '#fffff0' }}>
                                <TableAntd.Summary.Cell index={0} colSpan={7} />
                                <TableAntd.Summary.Cell index={1}>
                                  <Text strong>Totals</Text>
                                </TableAntd.Summary.Cell>
                                <TableAntd.Summary.Cell index={2}>
                                  <Text strong>{formatPrice(totalMaterial)}</Text>
                                </TableAntd.Summary.Cell>
                                <TableAntd.Summary.Cell index={3}>
                                  <Text strong>{formatPrice(totalLabor)}</Text>
                                </TableAntd.Summary.Cell>
                                <TableAntd.Summary.Cell index={4}>
                                  <Text strong>{formatPrice(total)}</Text>
                                </TableAntd.Summary.Cell>
                              </TableAntd.Summary.Row>
                            </>
                          );
                        }}
                      />
                    ),
                    rowExpandable: record => record.name !== 'Not Expandable',
                  }}
                />
              </Row>
            )}
            <Row>
              <Col span={24} style={style.footer.col}>
                <Space style={{ float: 'right' }}>
                  {estimateData.approvedBy === null && user.roles.find(role => role.name === 'ROLE_ADMIN') && (
                    <Popconfirm
                      icon={false}
                      title={t('messages:estimates.confirmApprove')}
                      onConfirm={() => handleApprove()}
                      okText={t('messages:yes')}
                      cancelText={t('messages:no')}
                    >
                      <Button loading={loading} type="button" color="primary">
                        <AuditOutlined />
                        {t('screens:estimate.btnApproveEstimate')}
                      </Button>
                    </Popconfirm>
                  )}
                  {estimateData.approvedBy !== null && user.roles.find(role => role.name === 'ROLE_ADMIN') && (
                    <>
                      <Popconfirm
                        icon={false}
                        title={t('messages:estimates.confirmUnApprove')}
                        onConfirm={() => handleUnApprove()}
                        okText={t('messages:yes')}
                        cancelText={t('messages:no')}
                      >
                        <Button loading={loading} type="button" color="warning">
                          <AuditOutlined />
                          {t('screens:estimate.btnUnApproveEstimate')}
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        icon={false}
                        title={t('messages:estimates.confirmSaveAndSendCustomers')}
                        onConfirm={() => handleSaveAndSendCustomers(values)}
                        okText={t('messages:yes')}
                        cancelText={t('messages:no')}
                      >
                        <Button
                          loading={loading}
                          color="primary"
                        >
                          <MailOutlined />
                          {t('screens:estimate.btnSaveAndSendCustomers')}
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        icon={false}
                        title={t('messages:confirmSaveAndSendSubcontractors')}
                        onConfirm={() => handleSaveAndSendSubcontractors(values)}
                        okText={t('messages:yes')}
                        cancelText={t('messages:no')}
                      >
                        <Button loading={loading} color="primary">
                          <MailOutlined />
                          {t('screens:estimate.btnSaveAndSendSubcontractors')}
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </Space>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <EstimateForm
        selectedRecord={selectedRecord}
        laborTypes={laborTypes}
        areas={areas}
        setAreas={setAreas}
        estimateData={estimateData}
        estimateDescriptions={estimateDescriptions}
        setEstimateData={setEstimateData}
        visible={visible}
        onClose={() => setVisible(false)}
      />
      <Modal
        title="Subcontractors"
        open={visibleSubVendors}
        onCancel={handleCancelSubVendors}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancelSubVendors}>
            Close
          </Button>,
        ]}
      >
        <Select
          mode="multiple"
          disabled={approvedBy !== null}
          showArrow
          tagRender={tagRender}
          style={{ width: '100%' }}
          value={
            completeEstimate
              .find(item => item.laborTypeId === selectedLaborType)
              ?.subVendors.map(subVendor => subVendor?.id) || []
          }
          options={allSubVendors
            .filter(subVendor => subVendor.laborTypes.find(laborType => laborType.id === selectedLaborType))
            .map(subVendor => ({
              value: subVendor.id,
              label: `${subVendor.id} - ${subVendor.companyName}`,
            }))}
          onChange={newValue => {
            const newCompleteEstimate = completeEstimate.map(estimate => {
              if (estimate.laborTypeId === selectedLaborType) {
                estimate.subVendors = newValue.map(value => {
                  const subVendor = allSubVendors.find(subVendor => subVendor.id === value);
                  return subVendor;
                });
              }
              return estimate;
            });
            setCompleteEstimate(newCompleteEstimate);
            handleSaveEstimateSubvendors();
          }}
          placeholder="Select Subcontractor..."
          maxTagCount="responsive"
        />
      </Modal>

      <ModalProposal
        record={estimateData}
        visible={showProposalModal}
        loading={loading}
        onSave={handleSendClient}
        onLoad={() => { setLoading(true) }}
        onClose={() => {
          setProposalModal(false);
        }}
      />
    </Box>
  );
}
