import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Button } from 'antd';
import moment from 'moment';
import './style.css';
import { profitAndLoss } from '~/services/hooks/quickbooksReports';

const { RangePicker } = DatePicker;

export default function ProfitAndLossReport() {
  const [dateRange, setDateRange] = useState([
    moment().startOf('year'), // Data inicial padrão: início do ano atual
    moment().endOf('year')     // Data final padrão: final do ano atual
  ]);

  const [dataSource, setDataSource] = useState([]);

  async function fetchData(startDate, endDate) {
    const data = await profitAndLoss({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
    if (data?.Rows?.Row) {
      setDataSource(parseProfitAndLossData(data));
    } else {
      console.error('Formato inesperado dos dados:', data);
    }
  }

  useEffect(() => {
    fetchData(dateRange[0], dateRange[1]);
  }, []);

  const columns = [
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      render: (text, record) => {
        const indent = record.level * 20;
        return <span style={{ paddingLeft: indent }}>{text}</span>;
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: text => {
        if (text === '') return '';
        const formattedNumber = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(Number(text));
        return formattedNumber;
      },
    },
  ];

  return (
    <div>
      {/* Selecionador de Data */}
      <div style={{ marginBottom: 16 }}>
        <RangePicker
          value={dateRange}
          onChange={(dates) => setDateRange(dates)}
          format="YYYY-MM-DD"
        />
        <Button 
          type="primary" 
          onClick={() => fetchData(dateRange[0], dateRange[1])}
          style={{ marginLeft: 10 }}
        >
          Buscar Relatório
        </Button>
      </div>

      {/* Tabela de Dados */}
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        rowClassName={record => (record.isSummary ? 'summary-row' : '')}
      />
    </div>
  );
}

// Função para formatar os dados da API
function parseProfitAndLossData(data) {
  if (!data?.Rows?.Row) return [];

  const rows = data.Rows.Row;
  const parsedRows = [];

  function traverseRows(rowsArray, level = 0) {
    if (!Array.isArray(rowsArray)) return;

    rowsArray.forEach(row => {
      if (row.Header) {
        const accountName = row.Header.ColData[0].value;
        parsedRows.push({
          key: `${accountName}-${level}-${Math.random()}`,
          account: accountName,
          total: '',
          level,
          isSummary: false,
        });
      }

      if (row.ColData) {
        const accountName = row.ColData[0].value;
        const totalValue = row.ColData[1].value;
        parsedRows.push({
          key: `${accountName}-${level}-${Math.random()}`,
          account: accountName,
          total: totalValue,
          level,
          isSummary: row.type === 'Data',
        });
      }

      if (row.Rows) {
        traverseRows(row.Rows.Row, level + 1);
      }

      if (row.Summary) {
        const summaryName = row.Summary.ColData[0].value;
        const totalValue = row.Summary.ColData[1]?.value;
        parsedRows.push({
          key: `${summaryName}-${level}-${Math.random()}`,
          account: summaryName,
          total: totalValue,
          level,
          isSummary: true,
        });
      }
    });
  }

  traverseRows(rows);
  return parsedRows;
}