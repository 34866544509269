import React, { useEffect } from 'react';
import { Modal, Spin } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createHotel, updateHotel } from '~/services/hooks/hotels';
import api from '~/services/api';

export default function HotelForm({ selectedRecord, visible, onClose, onUpdateItem, onInsertItem }) {
  const initialValues = {
    id: null,
    name: '',
    customer: null,
    customer_id: ''
  };

  const [hotel, setHotel] = useState({ ...initialValues, ...selectedRecord });
  const { Option } = Select;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);

  const handleSave = async values => {
    setLoading(true);
    let customerSelected = customers.filter(item => item.id.toString() === values.customer_id);

    let newHotel = {
      id: values.id,
      name: values.name,
      customer: customerSelected.length > 0 ? customerSelected[0] : null,
    };

    if (values.id !== null) {
      const data = await updateHotel(newHotel);
      onUpdateItem(data);
    } else {
      const data = await createHotel(newHotel);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const fetchRecords = async () => {
    setLoading(true);
    const { data } = await api.get('/customers/findAll');
    setCustomers(data);

    if (selectedRecord) {
      let h = {
        id: selectedRecord.id,
        name: selectedRecord.name,
        customer: selectedRecord.customer,
        customer_id: selectedRecord.customer ? selectedRecord.customer.id.toString() : ''
      }
      setHotel(h);
    } else {
      setHotel(initialValues);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  const hotelchema = Yup.object().shape({
    name: Yup.string().required(),
    customer_id: Yup.string().required("Client is required"),
  });

  return (
    <Formik initialValues={hotel} enableReinitialize onSubmit={handleSave} validationSchema={hotelchema}>
      {({ errors, isSubmitting, submitForm, resetForm, values }) => (
        <Modal
          width="850px"
          title={values.id !== null ? t('screens:hotel.btnEdit') : t('screens:hotel.btnNew')}
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                  field="name"
                  label={t('screens:hotel.data.name')}
                  required
                  error={errors.name}
                >
                  <Input name="name" />
                </FormControl>
                <FormControl
                  cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                  field="customer_id"
                  label={t('screens:hotel.data.customer')}
                  required
                  error={errors.customer_id}
                >
                  <Select showSearch name="customer_id" label={t('screens:users.data.customer')}>
                    {customers.map(item => {
                      return (
                        <Option key={item.id} value={item.id.toString()}>
                          {item.companyName}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
