import React, { useEffect, useState } from 'react';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Typography, Table as TableAntd, Button as ButtonAntd, Progress, Tooltip, Skeleton, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import Box from '~/components/Box';
import Row from '~/components/Row';
import { Table } from '~/components/Table';
import AppCertificationForm from './appCertificationForm';
import Payments from './payments';
import { findAllByProject, findAllPayAppItem } from '~/services/hooks/payApps';
import { paginationPayment } from '~/services/hooks/payments';
import { formatPrice, formatPercent } from '~/Utils';
import 'antd/dist/antd.css';
import './index.css';

const { Text } = Typography;

export default function Subcontractors({ project }) {
  const projectId = project.id;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedSubcontractor, setSelectedSubcontractor] = useState(null);
  const [selectedWork, setSelectedWork] = useState(null);
  const [payItems, setPayItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [payVisible, setPayVisible] = useState(false);
  const [allPayApps, setAllPayApps] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    findAllByProject({ projectId: projectId })
      .then(async payApps => {
        // get all payApps items
        let payAppPromises = payApps.map(payApp => {
          return findAllPayAppItem({ payAppId: payApp.id }).then(async items => {
            // order items by changeOrderId if is null put at the start
            items.sort((a, b) => {
              if (a.changeOrderId === null) return -1;
              if (b.changeOrderId === null) return 1;
              return a.changeOrderId - b.changeOrderId;
            });
            // get all payments related to the item
            let itemPromises = items.map(item => {
              return paginationPayment({
                page: 0,
                maxSize: true,
                sortBy: 'date',
                sortDirection: 'DESC',
                filters: [{ columnJoin: 'contractItem', field: 'id', value: item.id, restriction: 'EQUAL' }],
              }).then(payments => {
                item.payments = payments.content;
                item.completedAndStored = item.payments.reduce((acc, item) => {
                  return acc + item.value;
                }, 0);
                item.thisApplication = item.payments[0] ? parseFloat(item.payments[0].value) : 0;
                item.previousApplication = item.completedAndStored - item.thisApplication;
                item.balanceToFinish = parseFloat(item.scheduledValue - item.previousApplication);
                item.retainagePercent = 10;
                item.retainage = parseFloat(item.completedAndStored * (item.retainagePercent / 100));
                return item;
              });
            });
            const items_1 = await Promise.all(itemPromises);

            // total values of payApp
            payApp.totalScheduledValue = items_1.reduce((acc, item) => {
              return acc + item.scheduledValue;
            }, 0);
            // total values of items completed and stored
            payApp.paidToDate = items_1.reduce((acc, item) => {
              return acc + parseFloat(item.completedAndStored);
            }, 0);
            // total values of retainage
            payApp.retainage = items_1.reduce((acc, item) => {
              return acc + parseFloat(item.retainage);
            }, 0);
            // total values of balance to finish
            payApp.balanceToBePaid = items_1.reduce((acc, item) => {
              return acc + parseFloat(item.balanceToFinish);
            }, 0);
            // change order sum
            payApp.changeOrderSum = items_1
              .filter(item => item.changeOrderId !== null)
              .reduce((acc, item) => {
                return acc + parseFloat(item.scheduledValue);
              }, 0);

            return { ...payApp, items: items_1 };
          });
        });
        const payApps_1 = await Promise.all(payAppPromises);
        setAllPayApps(payApps_1);
        setLoading(false);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const viewAppCertificationForm = subcontractor => {
    setSelectedSubcontractor(subcontractor);
    setVisible(true);
  };

  const viewPayments = work => {
    setSelectedWork(work);
    setPayItems(work.payments);
    setPayVisible(true);
  };

  const tableColumns = [
    {
      title: t('screens:workbook.subcontractor.payAppId'),
      dataIndex: 'id',
      key: 'id',
      hidden: true,
      ellipsis: { showTitle: false },
      align: 'center',
      width: '10%',
    },
    {
      title: t('screens:workbook.subcontractor.companyName'),
      dataIndex: 'contract',
      key: 'contract.subVendor.companyName',
      ellipsis: { showTitle: false },
      render: contract => {
        return contract.subVendor.companyName;
      },
    },
    {
      title: t('screens:workbook.subcontractor.approvedBy'),
      dataIndex: 'approvedBy',
      key: 'approvedBy',
      ellipsis: { showTitle: false },
      render: approvedBy => {
        return approvedBy ? approvedBy : <Text type="danger">{t('screens:workbook.subcontractor.notApproved')}</Text>;
      },
    },
    {
      title: t('screens:workbook.subcontractor.contractAmountAndCOs'),
      dataIndex: 'totalScheduledValue',
      key: 'contractAmountAndCOs',
      ellipsis: { showTitle: false },
      render: totalScheduledValue => <>{formatPrice(totalScheduledValue)}</>,
    },
    {
      title: t('screens:workbook.subcontractor.paid_to_date'),
      dataIndex: 'paidToDate',
      key: 'paidToDate',
      ellipsis: { showTitle: false },
      render: paidToDate => {
        return formatPrice(paidToDate);
      },
    },
    {
      title: t('screens:workbook.subcontractor.retainage'),
      dataIndex: 'retainage',
      key: 'retainage',
      ellipsis: { showTitle: false },
      render: retainage => {
        return formatPrice(retainage);
      },
    },
    {
      title: t('screens:workbook.subcontractor.balance_to_be_paid'),
      dataIndex: 'balanceToBePaid',
      key: 'balanceToBePaid',
      ellipsis: { showTitle: false },
      render: balanceToBePaid => {
        return formatPrice(balanceToBePaid);
      },
    },
    {
      title: t('screens:workbook.subcontractor.notes'),
      dataIndex: 'note',
      key: 'note',
      ellipsis: { showTitle: false },
    },
    {
      key: 'actions',
      align: 'center',
      width: '80px',
      render: record => (
        <Tooltip title="APPLICATION AND CERTIFICATION FOR PAYMENT">
          <ButtonAntd type="link" icon={<EyeOutlined />} onClick={() => viewAppCertificationForm(record)} />
        </Tooltip>
      ),
    },
  ].filter(item => !item.hidden);

  const tableColumnsDetails = [
    {
      title: t('screens:workbook.subcontractor.work.id'),
      key: 'id',
      width: '80px',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        return record.changeOrderId === null ? `CO-${record?.id}` : `CA-${record.changeOrderId}`;
      },
    },
    {
      title: t('screens:workbook.subcontractor.work.description'),
      dataIndex: 'description',
      key: 'description',
      ellipsis: { showTitle: false },
    },
    {
      title: t('screens:workbook.subcontractor.work.scheduled_value'),
      dataIndex: 'scheduledValue',
      key: 'scheduledValue',
      ellipsis: { showTitle: false },
      render: scheduledValue => {
        return formatPrice(scheduledValue);
      },
    },
    {
      title: t('screens:workbook.subcontractor.work.application'),
      dataIndex: 'previousApplication',
      key: 'previousApplication',
      ellipsis: { showTitle: false },
      render: previousApplication => {
        return formatPrice(previousApplication);
      },
    },
    {
      title: t('screens:workbook.subcontractor.work.this_application'),
      key: 'thisApplication',
      dataIndex: 'thisApplication',
      editable: false,
      ellipsis: { showTitle: false },
      render: thisApplication => {
        return formatPrice(thisApplication);
      },
    },
    {
      title: t('screens:workbook.subcontractor.work.total_completed_and_stored'),
      dataIndex: 'completedAndStored',
      key: 'completedAndStored',
      ellipsis: { showTitle: false },
      render: completedAndStored => {
        return formatPrice(completedAndStored);
      },
    },
    {
      title: t('screens:workbook.subcontractor.work.percentage'),
      key: 'percentage',
      ellipsis: { showTitle: false },
      width: '110px',
      render: record => {
        const result = parseFloat(record.completedAndStored) / record.scheduledValue;
        return <Progress percent={(result * 100).toFixed(2)} size="small" style={{ maxWidth: '80px' }} />; // return formatPercent(result);
      },
    },
    {
      title: t('screens:workbook.subcontractor.work.balance_to_finish'),
      dataIndex: 'balanceToFinish',
      key: 'balanceToFinish',
      ellipsis: { showTitle: false },
      render: balanceToFinish => {
        return formatPrice(balanceToFinish);
      },
    },
    {
      title: t('screens:workbook.subcontractor.work.retainage'),
      dataIndex: 'retainage',
      key: 'retainage',
      ellipsis: { showTitle: false },
      render: retainage => {
        return formatPrice(retainage);
      },
    },
    {
      key: 'actions',
      align: 'center',
      width: '50px',
      render: record => (
        <Tooltip title="OPEN PAYMENTS">
          <ButtonAntd type="link" icon={<EditOutlined />} onClick={() => viewPayments(record)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Box>
        <Row>
          <Skeleton loading={loading} active>
            {allPayApps.length > 0 ? (
              <Table
                bordered
                size="small"
                rowKey="id"
                pagination={false}
                loading={loading}
                columns={tableColumns}
                dataSource={allPayApps}
                expandable={{
                  expandedRowRender: record => (
                    <TableAntd
                      rowClassName={record => (record.changeOrderId === null ? 'editable-row' : 'editable-row-co')}
                      bordered
                      rowKey="id"
                      pagination={false}
                      loading={loading}
                      columns={tableColumnsDetails}
                      dataSource={record.items}
                      showHeader={true}
                      size="small"
                      title={() => 'Subcontractor Details'}
                      summary={pageData => {
                        let totalScheduledValues = 0;
                        let totalApplications = 0;
                        let totalThisApplications = 0;
                        let totalCompletedAndStored = 0;
                        let totalBalanceToFinish = 0;
                        let totalRetainage = 0;
                        pageData.forEach(
                          ({
                            scheduledValue,
                            previousApplication,
                            thisApplication,
                            completedAndStored,
                            balanceToFinish,
                            retainage,
                          }) => {
                            totalScheduledValues += scheduledValue;
                            totalApplications += previousApplication;
                            totalThisApplications += thisApplication;
                            totalCompletedAndStored += completedAndStored;
                            totalBalanceToFinish += balanceToFinish;
                            totalRetainage += retainage;
                          }
                        );
                        return (
                          <>
                            <TableAntd.Summary.Row>
                              <TableAntd.Summary.Cell index={0} />
                              <TableAntd.Summary.Cell index={1}>
                                <Text strong>Total</Text>
                              </TableAntd.Summary.Cell>
                              <TableAntd.Summary.Cell index={2}>
                                <Text strong>{formatPrice(totalScheduledValues)}</Text>
                              </TableAntd.Summary.Cell>
                              <TableAntd.Summary.Cell index={3}>
                                <Text strong>{formatPrice(totalApplications)}</Text>
                              </TableAntd.Summary.Cell>
                              <TableAntd.Summary.Cell index={4}>
                                <Text strong>{formatPrice(totalThisApplications)}</Text>
                              </TableAntd.Summary.Cell>
                              <TableAntd.Summary.Cell index={5}>
                                <Text strong>{formatPrice(totalCompletedAndStored)}</Text>
                              </TableAntd.Summary.Cell>
                              <TableAntd.Summary.Cell index={6}>
                                <Text strong>{formatPercent(totalCompletedAndStored / totalScheduledValues)}</Text>
                              </TableAntd.Summary.Cell>
                              <TableAntd.Summary.Cell index={7}>
                                <Text strong>{formatPrice(totalBalanceToFinish)}</Text>
                              </TableAntd.Summary.Cell>
                              <TableAntd.Summary.Cell index={8}>
                                <Text strong>{formatPrice(totalRetainage)}</Text>
                              </TableAntd.Summary.Cell>
                            </TableAntd.Summary.Row>
                          </>
                        );
                      }}
                    />
                  ),
                  rowExpandable: record => record.name !== 'Not Expandable',
                }}
                summary={pageData => {
                  let totalContractAmountAndCOs = 0;
                  let totalPaidToDate = 0;
                  let totalRetainage = 0;
                  let totalBalanceToBePaid = 0;
                  pageData.forEach(({ totalScheduledValue, paidToDate, retainage, balanceToBePaid }) => {
                    totalContractAmountAndCOs += totalScheduledValue;
                    totalPaidToDate += paidToDate;
                    totalRetainage += retainage;
                    totalBalanceToBePaid += balanceToBePaid;
                  });
                  return (
                    <>
                      <TableAntd.Summary.Row>
                        <TableAntd.Summary.Cell index={1} colSpan={2} />
                        <TableAntd.Summary.Cell index={2}>
                          <Text strong>Total</Text>
                        </TableAntd.Summary.Cell>
                        <TableAntd.Summary.Cell index={3}>
                          <Text strong>{formatPrice(totalContractAmountAndCOs)}</Text>
                        </TableAntd.Summary.Cell>
                        <TableAntd.Summary.Cell index={4}>
                          <Text strong>{formatPrice(totalPaidToDate)}</Text>
                        </TableAntd.Summary.Cell>
                        <TableAntd.Summary.Cell index={5}>
                          <Text strong>{formatPrice(totalRetainage)}</Text>
                        </TableAntd.Summary.Cell>
                        <TableAntd.Summary.Cell index={6}>
                          <Text strong>{formatPrice(totalBalanceToBePaid)}</Text>
                        </TableAntd.Summary.Cell>
                        <TableAntd.Summary.Cell index={7} />
                      </TableAntd.Summary.Row>
                    </>
                  );
                }}
              />
            ) : (
              <div style={{ width: '100%', textAlign: 'center', height: '50vh' }}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  // image={Empty.PRESENTED_IMAGE_SIMPLE} // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  // imageStyle={{ height: '100%', width: '100%' }}
                  style={{ marginTop: '50px' }} // style={{ marginTop: '50px', position: 'absolute', left: '50%', transform: 'translate(-50%, 0)' }}
                  description={
                    <>
                      <Text strong>{t('screens:workbook.subcontractor.noData')}</Text>
                      <p>
                        <Text type="secondary">{t('screens:workbook.subcontractor.noDataDescription')}</Text>
                      </p>
                    </>
                  }
                ></Empty>
              </div>
            )}
          </Skeleton>
        </Row>
      </Box>
      { selectedSubcontractor && visible && (
      <AppCertificationForm
        selectedSubcontractor={selectedSubcontractor}
        onClose={() => setVisible(false)}
        visible={visible}
      />)}
      <Payments
        selectedWork={selectedWork}
        visible={payVisible}
        onClose={() => setPayVisible(false)}
        fetchData={fetchData}
        payItems={payItems}
        setPayItems={setPayItems}
      />
    </>
  );
}
