import i18next from 'i18next';
import { message } from 'antd';
import history from '~/services/history';

const online = navigator.onLine;

const showMessage = text => {
  message.error(text);
};

const errorCodeMap = {
  duplicateUsers: 'messages:duplicatedEmail',
  duplicateCustomer: 'messages:duplicateCustomer',
  duplicateHotel: 'messages:duplicateHotel',
  duplicateSub_vendor: 'messages:duplicateSubVendor',
  foreignKeyViolation: 'messages:foreignKeyViolation',
};

const fieldErrorMap = {
  contractPassword: 'messages:contractPasswordError',
  contractItemOrChangeOrder: 'messages:contractItemOrChangeOrderError',
  proposalClientApprove: 'messages:proposalClientApproveError',
};

const handle422Errors = (errors, e422) => {
  if (errors && e422) {
    return errors;
  } else {
    showMessage(i18next.t('messages:invalidData'));
  }
};

const handleDetailedErrors = errors => {
  errors.forEach(item => {
    const errorKey = errorCodeMap[item.sqlState] || fieldErrorMap[item.field];
    if (errorKey) {
      showMessage(i18next.t(errorKey));
    } else {
      showMessage(i18next.t(`messages:${item.errorCode}`, { defaultValue: i18next.t('messages:randomError') }));
    }
  });
};

const handleErrorStatus = (response, e422) => {
  const { status, data } = response;

  switch (status) {
    case 422:
      return handle422Errors(data.errors, e422);

    case 404:
      history.push('/404');
      break;

    case 403:
      showMessage(i18next.t('messages:unauthorized'));
      break;

    case 401:
    case 400:
      if (data.errors) {
        handleDetailedErrors(data.errors);
      } else {
        showMessage(i18next.t('messages:authError'));
      }
      break;

    case 500:
      showMessage(i18next.t('messages:randomError'));
      break;

    default:
      break;
  }

  return {};
};

const errorHandler = (exception, e422 = true) => {
  const { response } = exception;

  if (!online) {
    showMessage(i18next.t('messages:offline'));
    return {};
  }

  if (response) {
    return handleErrorStatus(response, e422);
  }

  if (exception.message === 'Network Error') {
    showMessage(i18next.t('messages:backendOff'));
  }
  return {};
};

export default errorHandler;
