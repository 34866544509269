import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Breadcrumb, Tag, Button as ButtonAntd, Col, Table } from 'antd';
import DefautLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Pagination from '~/components/Pagination';
import { TableActions } from '~/components/Table';
import ProjectForm from '~/pages/BidsList/form';
import { BreadcrumbCustom } from '~/styles/global';
import history from '~/services/history';
import Filter from './filter';
import Row from '~/components/Row';
import { projectStatus } from '~/services/lists/projectStatus';
import { paginationProjects, deleteProject, findAllProjectTypes } from '~/services/hooks/projects';
import { getProjectStatusColor } from '~/Utils';
// import { findAllHotels } from '~/services/hooks/hotels';

export default function ProjectsList(data) {
  const customer = data?.location?.state?.data;
  // remove projects list from customer data
  delete customer.projects;
  const customerId = customer.id;
  const customerName = customer.companyName;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  // when passing data from another page, for example, customer data with projects list ( history.push({pathname: '/projects-list', state: { data: record },}) ), use the following line
  // const [tableData, setTableData] = useState(data.location.state.data.projects.filter(project => project.flag === 'PROJECT'));
  const [sortBy, setSortBy] = useState('description');
  const defaultFilter = { field: 'flag', value: 'PROJECT', restriction: 'EQUAL' };
  const defaultFilterCustomer =
    customerId !== undefined ? { columnJoin: 'customer', field: 'id', value: customerId, restriction: 'EQUAL' } : {};
  const [filters, setFilters] = useState([]);
    const [hotels, setHotels] = useState(customer.hotels);
  // const [hotels, setHotels] = useState([]);

  // const fetchHotels = async () => {
  //   setLoading(true);
  //   const hotelFilters = [{ columnJoin: 'customer', field: 'id', value: customerId, restriction: 'EQUAL' }];
  //   const data = await findAllHotels(hotelFilters);
  //   setHotels(data);
  //   setLoading(false);
  // }

  useEffect(() => {
    setLoading(true);
    findAllProjectTypes().then(data => setProjectTypes(data));
    setLoading(false);
  }, []);

  const fetchData = async page => {
    setLoading(true);
    const data = await paginationProjects({
      page: page - 1,
      sortBy,
      filters: [...filters, defaultFilter, defaultFilterCustomer],
    });

    // Organizando os dados para serem exibidos na tabela de hotéis
    const hotelsMap = {};
    data.content.forEach(project => {
      if (!hotelsMap[project.hotelData?.id]) {
        hotelsMap[project.hotelData.id] = { ...project.hotelData, projects: [] };
      }
      hotelsMap[project.hotelData?.id].projects.push(project);
    });

    setTableData(Object.values(hotelsMap)); // Converte de objeto para array
    setMeta({
      first: data.first,
      last: data.last,
      numberOfElements: data.numberOfElements,
      pageNumber: data.pageable.pageNumber + 1,
      pageSize: data.pageable.pageSize,
      totalElements: data.totalElements,
    });

    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [filters, sortBy]);

  const fetchProjectTypes = async () => {
    setLoading(true);
    const data = await findAllProjectTypes();
    setProjectTypes(data);
    setLoading(false);
  };

  const handleDelete = async record => {
    await deleteProject(record.id);
    fetchData(1);
  };

  useEffect(() => {
    fetchProjectTypes();
    // fetchHotels();
  }, []);

  const handleEdit = record => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  // Configuração das colunas para a tabela principal (Hotéis)
  const tableColumns = [
    {
      title: t('screens:bids.data.hotel'),
      dataIndex: 'name', 
    },
  ];

  // Função para renderizar os projetos na linha expandida
  const expandedRowRender = hotel => {
    const projectColumns = [
      {
        title: t('screens:bids.data.hotel'),
        dataIndex: 'hotel',
        key: 'hotel',
        render: hotel => (hotel ? hotel.name : null),
      },
      {
        title: t('screens:projects.data.project_description'),
        dataIndex: 'description',
        key: 'description',
        render: (text, record) => (
          <ButtonAntd
            type="link"
            onClick={() =>
              history.push({
                pathname: '/project-details',
                state: { data: record, customer: customer },
              })
            }
            title={t('messages:view')}
          >
            {text}
          </ButtonAntd>
        ),
      },
      {
        title: t('screens:bids.data.type'),
        dataIndex: 'type',
        key: 'type',
        render: type => {
          const projectType = projectTypes.find(projectType => projectType.name === type);
          return projectType ? projectType.description : null;
        },
      },
      {
        title: t('screens:projects.status'),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: '100px',
        render: status => (
          <Tag color={getProjectStatusColor(status.toLowerCase())}>
            {t('screens:projects.data.status.' + status.toLowerCase()).toUpperCase()}
          </Tag>
        ),
      },
      {
        title: t('screens:projects.data.actions'),
        key: 'actions',
        align: 'center',
        width: '10%',
        className: 'table-action',
        render: record => (
          <TableActions>
            <Button
              onClick={() =>
                history.push({
                  pathname: '/project-details',
                  state: { data: record, customer: customer },
                })
              }
              title={t('messages:view')}
            >
              <EyeOutlined />
            </Button>
            <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
              <EditOutlined />
            </Button>

            <Popconfirm
              icon={false}
              title={t('messages:confirmDelete')}
              onConfirm={() => handleDelete(record.id)}
              okText={t('messages:yes')}
              cancelText={t('messages:no')}
            >
              <Button title={t('messages:delete')}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </TableActions>
        ),
      },
    ];

    return <Table columns={projectColumns} dataSource={hotel.projects} rowKey="id" pagination={false} />;
  };

  const style = {
    pageTitle: { marginRight: 'auto', width: '100%' },
    btnNew: {
      col: { marginLeft: 'auto', paddingBottom: '20px' },
      btn: { marginLeft: 'auto' },
    },
  };

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projects')}</Breadcrumb.Item>
      <Breadcrumb.Item href="/clients">{t('menus:clients')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:projectList')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefautLayout breadcrumb={breadcrumb}>
      {customerName !== undefined && customerName !== null && customerName !== '' ? (
        <PageTitle title={customerName} subtitle={t('menus:projectList')} />
      ) : (
        <PageTitle title={t('menus:projectList')} />
      )}
      <Filter projectStatus={projectStatus} hotels={hotels} isLoading={loading} setFilters={setFilters} />
      <Box>
        <Row>
          <Col span={12} style={style.btnNew.col}>
            <Button
              loading={loading}
              color="primary"
              onClick={() => {
                setSelectedRecord(null);
                setShowForm(true);
              }}
              style={style.btnNew.btn}
            >
              <PlusOutlined />
              {t('screens:bids.btnNew')}
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
          expandedRowRender={expandedRowRender}
        />
        <Pagination
          onChange={value => {
            fetchData(value);
          }}
          meta={meta}
        />
      </Box>
      <ProjectForm
        record={selectedRecord}
        projectTypes={projectTypes}
        hotels={hotels}
        setHotels={setHotels}
        visible={showForm}
        onClose={() => {
          setShowForm(false);
          setSelectedRecord(null);
          fetchData(1);
        }}
        customer={customer}
      />
    </DefautLayout>
  );
}
