import * as Yup from 'yup';

const name = Yup.string().min(2, 'Too Short!').max(100, 'Field must have at most 100 characters!');
const phone = Yup.string().min(2, 'Too Short!').max(27, 'Too Long!'); 
const email = Yup.string().typeError('Insert your e-mail').email('Invalid email').min(2, 'Too Short!').max(255, 'Too Long!');

export const primaryContactSchema = Yup.object().shape({
  firstName: name.required().max(25, 'Field must have at most 25 characters'),
  lastName: name.required().max(25, 'Field must have at most 25 characters'),
  jobTitle: name.required().max(40, 'Field must have at most 40 characters'),
  mainPhone: phone.max(27, 'Field must have at most 27 characters'),
  mainEmail: email.required('Email is required'),
  workPhone: phone.max(27, 'Field must have at most 27 characters'),
  ccEmail: email.max(255, 'Field must have at most 255 characters'),
  mobilePhone: phone.max(20, 'Field must have at most 20 characters'),
});

export const addressSchema = Yup.object().shape({
  addressLineOne: name.required().max(40, 'Field must have at most 40 characters'),
  addressLineTwo: name.max(40, 'Field must have at most 40 characters'),
  city: name.required().max(16, 'Field must have at most 16 characters'),
  state: name.required().max(21, 'Field must have at most 21 characters'),
  country: name.required().max(25, 'Field must have at most 25 characters'),
  type: Yup.string().required(),
  main: Yup.bool().required(),
  note: Yup.string().max(41, 'Field must have at most 41 characters'),
});

export const customerSchema = Yup.object().shape({
  companyName: name.required(),
  salesTaxCode: Yup.string().required(),
  primaryContactFirstName: name.required().max(25, 'Field must have at most 25 characters'),
  primaryContactLastName: name.required().max(25, 'Field must have at most 25 characters'),
  primaryContactJobTitle: name.required().max(40, 'Field must have at most 40 characters'),
  addresses: Yup.array().of(addressSchema).min(1, 'At least one address is required'),
});
