import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker, Checkbox } from 'formik-antd';
import {
  Col,
  Modal,
  Spin,
  Divider,
  Input as InputAntd,
  Select as SelectAntd,
  Space,
  Tooltip,
  message,
  Button as ButtonAntd,
  Typography,
} from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import errorHandler from 'Utils/errorHandler';
import Button from '~/components/Button';
import { Footer, TabProject } from './styles';
import Box from '~/components/Box';
import { projectStatus } from '~/services/lists/projectStatus';
import { findAllCountries } from '~/services/hooks/countries';
import { createProject, updateProject } from '~/services/hooks/projects';
import moment from 'moment';
import { format } from 'date-fns';
import { uploadFiles } from '~/services/hooks/files';
import { createHotel } from '~/services/hooks/hotels';
import UploadFile from '~/components/UploadFile';

const initialValues = {
  id: null,
  hotelId: null,
  description: '',
  flag: 'BID',
  status: 'NEW',
  type: 'OTHER_COMMERCIAL_GENERAL_CONTRACTOR',
  startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
  endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
  actualEndDate: moment().format('YYYY-MM-DD HH:mm:ss'),
  deadlineDate: null,
  tenantsContractInPlace: false,
  tenantsInsuranceOnFile: false,
  insuranceExpirationDate: moment().format('YYYY-MM-DD HH:mm:ss'),
  contract: null,
  tenantInsurance: null,
  property: {
    id: null,
    name: '',
    phone: '',
    website: '',
    address: {
      id: null,
      type: 'BILLING',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      country: 'UNITED_STATES_AMERICA',
      zipCode: '',
      main: true,
      note: '',
      uuid: 0,
    },
  },
  ownership: {
    id: null,
    name: '',
    representativeCompany: '',
    representativeName: '',
    representativePhone: '',
    representativeEmail: '',
    address: {
      id: null,
      type: 'BILLING',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      country: 'UNITED_STATES_AMERICA',
      zipCode: '',
      main: true,
      note: '',
      uuid: 0,
    },
  },
  architect: {
    id: null,
    companyName: '',
    representativeName: '',
    representativePhone: '',
    address: {
      id: null,
      type: 'BILLING',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      country: 'UNITED_STATES_AMERICA',
      zipCode: '',
      main: true,
      note: '',
      uuid: 0,
    },
  },
  designer: {
    id: null,
    companyName: '',
    representativeName: '',
    representativePhone: '',
    address: {
      id: null,
      type: 'BILLING',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      country: 'UNITED_STATES_AMERICA',
      zipCode: '',
      main: true,
      note: '',
      uuid: 0,
    },
  },
  typeDescription: 'Other Commercial General Contractor',
  basePrice: 0,
  selections: 0,
  purchaseOrders: 0,
  totalPrice: 0,
  invoiced: 0,
  payments: 0,
  amountDue: 0,
};

export default function ProjectForm({ visible, onClose, record, projectTypes, hotels, setHotels, customer }) {
  initialValues.customerId = customer.id;
  const projectData = init(record);

  const { t } = useTranslation();
  const { Option } = Select;
  const { Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [uploadContract, setUploadContract] = useState([]);
  const [uploadInsurance, setUploadInsurance] = useState([]);
  const [selectedTab, setSelectedTab] = useState('1');
  const inputRef = useRef(null);
  const [hotelName, setHotelName] = useState();

  const onHotelNameChange = event => {
    setHotelName(event.target.value);
  };

  function init(record) {
    const data = { ...initialValues, ...record };

    if (record && record.id) {
      const architect = {
        id: record.architect?.id ? record.architect.id : null,
        companyName: record.architect ? record.architect.companyName : '',
        representativeName: record.architect ? record.architect.representativeName : '',
        representativePhone: record.architect ? record.architect.representativePhone : '',
        address: {
          id: record.architect && record.architect.address ? record.architect.address.id : null,
          type: record.architect && record.architect.address ? record.architect.address.type : 'BILLING',
          addressLineOne: record.architect && record.architect.address ? record.architect.address.addressLineOne : '',
          addressLineTwo: record.architect && record.architect.address ? record.architect.address.addressLineTwo : '',
          city: record.architect && record.architect.address ? record.architect.address.city : '',
          state: record.architect && record.architect.address ? record.architect.address.state : '',
          country:
            record.architect && record.architect.address ? record.architect.address.country : 'UNITED_STATES_AMERICA',
          zipCode: record.architect && record.architect.address ? record.architect.address.zipCode : '',
          main: record.architect && record.architect.address ? record.architect.address.main : true,
          note: record.architect && record.architect.address ? record.architect.address.note : '',
          uuid: record.architect && record.architect.address ? record.architect.address.uuid : '0',
        },
      };

      const designer = {
        id: record.designer?.id ? record.designer.id : null,
        companyName: record.designer ? record.designer.companyName : '',
        representativeName: record.designer ? record.designer.representativeName : '',
        representativePhone: record.designer ? record.designer.representativePhone : '',
        address: {
          id: record.designer && record.designer.address ? record.designer.address.id : null,
          type: record.designer && record.designer.address ? record.designer.address.type : 'BILLING',
          addressLineOne: record.designer && record.designer.address ? record.designer.address.addressLineOne : '',
          addressLineTwo: record.designer && record.designer.address ? record.designer.address.addressLineTwo : '',
          city: record.designer && record.designer.address ? record.designer.address.city : '',
          state: record.designer && record.designer.address ? record.designer.address.state : '',
          country:
            record.designer && record.designer.address ? record.designer.address.country : 'UNITED_STATES_AMERICA',
          zipCode: record.designer && record.designer.address ? record.designer.address.zipCode : '',
          main: record.designer && record.designer.address ? record.designer.address.main : true,
          note: record.designer && record.designer.address ? record.designer.address.note : '',
          uuid: record.designer && record.designer.address ? record.designer.address.uuid : '0',
        },
      };

      data.architect = architect;
      data.designer = designer;
      data.hotelId = record.hotelId;
    }
    return data;
  }

  const handleAddHotel = async setFieldValue => {
    // Verifica se o hotel já existe
    const hotelExists = hotels.find(hotel => hotel.name === hotelName);
    if (hotelExists) {
      message.warning('Hotel already exists');
      return;
    }
  
    // Cria o novo hotel no banco de dados
    const data = await createHotel({
      id: null,
      name: hotelName,
      customer: customer,
    });
  
    // Atualiza a lista de hotéis e reseta o nome
    setHotels([...hotels, data]);
    setHotelName('');
  
    // Atualiza o campo hotelId no Formik com o ID do novo hotel
    setFieldValue('hotelId', data.id);
  };

  // const handleAddHotel = async setFieldValue => {
  //   // verify if the hotel already exists
  //   const areaExists = hotels.find(hotel => hotel.name === hotelName);
  //   if (areaExists) {
  //     message.warning('Hotel already exists');
  //     return;
  //   }
  //   // create the new hotel in the database
  //   const data = await createHotel({
  //     id: null,
  //     name: hotelName,
  //     customer: customer,
  //   });
  //   setHotels([...hotels, data]);
  //   setHotelName('');
  //   setFieldValue('hotel', data);
  // };

  const fetchCountries = async () => {
    setLoading(true);
    const data = await findAllCountries();
    setCountries(data);
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);

    if (selectedTab == '5') {
      values.startDate = format(new Date(values.startDate), "yyyy-MM-dd'T'HH:mm:ss");
      values.endDate = format(new Date(values.endDate), "yyyy-MM-dd'T'HH:mm:ss");
      values.actualEndDate = format(new Date(values.actualEndDate), "yyyy-MM-dd'T'HH:mm:ss");
      values.deadlineDate = values.deadlineDate ? format(new Date(values.deadlineDate), "yyyy-MM-dd'T'HH:mm:ss") : null;
      values.insuranceExpirationDate = format(new Date(values.insuranceExpirationDate), "yyyy-MM-dd'T'HH:mm:ss");

      if (uploadContract.length < 1) {
        values.contract = null;
      } else {
        const data = await uploadFiles(uploadContract);
        values.contract = data[0];
      }
      if (uploadInsurance.length < 1) {
        values.tenantInsurance = null;
      } else {
        const data = await uploadFiles(uploadInsurance);
        values.tenantInsurance = data[0];
      }

      try {
        // UPDATE PROJECT
        if (values.id !== null) {
          await updateProject(values);
        }
        // CREATE PROJECT
        else {
          await createProject(values);
        }

        onClose();
      } catch (error) {
        setErrors(errorHandler(error));
      }
    }
    setLoading(false);
    onChangeTab((parseInt(selectedTab) + 1).toString());
  };

  const onChangeTab = key => {
    setSelectedTab(key);
  };

  useEffect(() => {
    setSelectedTab('1');
    if (visible) {
      fetchCountries();
    }
  }, [visible]);

  const validationSchema1 = Yup.object().shape({
    description: Yup.string().required(),
    type: Yup.string().required(),
    status: Yup.string().required(),
  });

  const validationSchema2 = Yup.object().shape({
    property: Yup.object().shape({
      name: Yup.string().required(),
      phone: Yup.string(),
      website: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        addressLineTwo: Yup.string(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
  });

  const validationSchema3 = Yup.object().shape({
    ownership: Yup.object().shape({
      name: Yup.string().required(),
      representativeCompany: Yup.string(),
      representativeName: Yup.string(),
      representativePhone: Yup.string(),
      representativeEmail: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        addressLineTwo: Yup.string(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
  });

  const validationSchema4 = Yup.object().shape({
    architect: Yup.object().shape({
      companyName: Yup.string().required(),
      representativeName: Yup.string(),
      representativePhone: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        addressLineTwo: Yup.string(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
  });

  const validationSchema5 = Yup.object().shape({
    description: Yup.string().required(),
    type: Yup.string().required(),
    status: Yup.string().required(),
    property: Yup.object().shape({
      name: Yup.string().required(),
      phone: Yup.string(),
      website: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        addressLineTwo: Yup.string(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
    ownership: Yup.object().shape({
      name: Yup.string().required(),
      representativeCompany: Yup.string(),
      representativeName: Yup.string(),
      representativePhone: Yup.string(),
      representativeEmail: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
    architect: Yup.object().shape({
      companyName: Yup.string().required(),
      representativeName: Yup.string(),
      representativePhone: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
    designer: Yup.object().shape({
      companyName: Yup.string().required(),
      representativeName: Yup.string(),
      representativePhone: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
  });

  const validationSchema5WithoutArchitect = Yup.object().shape({
    description: Yup.string().required(),
    type: Yup.string().required(),
    status: Yup.string().required(),
    property: Yup.object().shape({
      name: Yup.string().required(),
      phone: Yup.string(),
      website: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        addressLineTwo: Yup.string(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
    ownership: Yup.object().shape({
      name: Yup.string().required(),
      representativeCompany: Yup.string(),
      representativeName: Yup.string(),
      representativePhone: Yup.string(),
      representativeEmail: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
    designer: Yup.object().shape({
      companyName: Yup.string().required(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
  });

  const validationSchema6 = Yup.object().shape({
    description: Yup.string().required(),
    type: Yup.string().required(),
    status: Yup.string().required(),
    property: Yup.object().shape({
      name: Yup.string().required(),
      phone: Yup.string(),
      website: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        addressLineTwo: Yup.string(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
    ownership: Yup.object().shape({
      name: Yup.string().required(),
      representativeCompany: Yup.string(),
      representativeName: Yup.string(),
      representativePhone: Yup.string(),
      representativeEmail: Yup.string(),
      address: Yup.object().shape({
        addressLineOne: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        country: Yup.string().required(),
        zipCode: Yup.string().required(),
      }),
    }),
  });

  return (
    <Formik
      initialValues={projectData} // record || initialValues
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={() =>
        Yup.lazy(values => {
          switch (selectedTab) {
            case '1':
              return validationSchema1;
            case '2':
              return validationSchema2;
            case '3':
              return validationSchema3;
            case '4':
              return values.architect?.companyName ||
                values.architect?.address?.addressLineOne ||
                values.architect?.address?.city ||
                values.architect?.address?.state ||
                values.architect?.address?.zipCode
                ? validationSchema4
                : validationSchema6;
            case '5':
              if (
                values.designer?.companyName ||
                values.designer?.address?.addressLineOne ||
                values.designer?.address?.city ||
                values.designer?.address?.state ||
                values.designer?.address?.zipCode
              ) {
                if (
                  values.architect?.companyName ||
                  values.architect?.address?.addressLineOne ||
                  values.architect?.address?.city ||
                  values.architect?.address?.state ||
                  values.architect?.address?.zipCode
                ) {
                  return validationSchema5;
                }
                return validationSchema5WithoutArchitect;
              }
              return validationSchema6;
            default:
              return validationSchema6;
          }
        })
      }
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue }) => (
        <>
          <Modal
            width="580px"
            title={values.id !== null ? t('screens:bids.btnEdit') : t('screens:bids.btnNew')}
            onCancel={onClose}
            afterClose={resetForm}
            open={visible}
            loading={loading || isSubmitting}
            footer={
              <Footer>
                <Space>
                  <Button loading={loading || isSubmitting} color={'default'} onClick={onClose}>
                    {'Cancel'}
                  </Button>
                  <Button loading={loading || isSubmitting} color={'primary'} onClick={submitForm}>
                    {selectedTab === '5' ? 'Save' : 'Continue'}
                  </Button>
                </Space>
              </Footer>
            }
          >
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <TabProject
                  type="card"
                  size={'small'}
                  activeKey={selectedTab}
                  onChange={onChangeTab}
                  items={[
                    // BID DATA
                    {
                      key: '1',
                      label: t('screens:bids.data.bid'),
                      disabled: selectedTab !== '1',
                      children: (
                        <Box>
                          <Row>
                            {/* HOTELS */}
                            <FormControl
                              field="hotelId"
                              label={t('screens:projects.hotel')}
                              required
                              error={(touched.hotelId && errors.hotelId) || errors.hotelId}
                            >
                              <SelectAntd
                                name="hotelId"
                                placeholder="Select hotel"
                                value={values.hotelId}
                                dropdownRender={menu => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: '8px 0',
                                      }}
                                    />
                                    <Space
                                      style={{
                                        padding: '0 8px 4px',
                                      }}
                                    >
                                      <InputAntd
                                        placeholder="Enter new hotel"
                                        ref={inputRef}
                                        value={hotelName}
                                        onChange={e => {
                                          onHotelNameChange(e);
                                        }}
                                      />
                                      <ButtonAntd
                                        type="text"
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                          if (hotelName && hotelName.length < 255) {
                                            handleAddHotel(setFieldValue);
                                          } else {
                                            message.warning('Hotel name cannot be empty and must be less than 255 characters');
                                          }
                                        }}
                                      >
                                        Add hotel
                                      </ButtonAntd>
                                      <Tooltip title="Warning: A hotel will be inserted for the customer, even if you do not complete this operation.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </Space>
                                  </>
                                )}
                                options={hotels?.map(a => ({
                                  label: a.name,
                                  value: a.id,
                                }))}
                                onChange={value => {
                                  setFieldValue('hotelId', value);
                                }}
                              />
                            </FormControl>
                            {/* <FormControl
                              cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                              field="hotelId"
                              label={t('screens:projects.hotel')}
                            >
                              <Select name="hotelId" label={t('screens:projects.hotel')}>
                                {(hotels || []).map(item => {
                                  return (
                                    <Option key={item.id} value={item.id}>
                                      {item.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormControl> */}
                            <FormControl
                              cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                              field="description"
                              error={(touched.description && errors.description) || errors.description}
                              label={t('screens:bids.data.description')}
                              required
                            >
                              <Input.TextArea name="description" autoSize maxLength={100} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
                              field="type"
                              label={t('screens:bids.status')}
                              required
                              error={(touched.status && errors.status) || errors.status}
                            >
                              <Select showSearch name="status" label={t('screens:bids.data.status')}>
                                {projectStatus.map(item => {
                                  return (
                                    <Option key={item.id} value={item.code}>
                                      {item.description}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl
                              cols={{ xs: 15, sm: 14, md: 14, lg: 14, xl: 14 }}
                              field="type"
                              label={t('screens:bids.data.type')}
                              required
                              error={(touched.type && errors.type) || errors.type}
                            >
                              <Select showSearch name="type" label={t('screens:bids.data.type')}>
                                {projectTypes?.map(item => {
                                  return (
                                    <Option key={item.name} value={item.name}>
                                      {item.description}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Row>
                          <Divider orientation="left" orientationMargin="0">
                            <Text>{t('screens:bids.data.schedule')}</Text>
                          </Divider>
                          <Row>
                            <FormControl
                              cols={{ xs: 8, sm: 7, md: 7, lg: 7, xl: 7 }}
                              field="deadlineDate"
                              label={t('screens:bids.data.deadlineDate')}
                            >
                              <DatePicker name="deadlineDate" format="MM/DD/Y" placeholder={t('messages:select')} />
                            </FormControl>
                          </Row>
                          <Divider orientation="left" orientationMargin="0">
                            <Text>{t('screens:bids.data.tenantsInfo')}</Text>
                          </Divider>
                          <Row>
                            {/* <FormControl field="tenantsContractInPlace" cols={{ xs: 24 }}>
                              <div style={{ paddingTop: 0 }}>
                                <Checkbox value="tenantsContractInPlace" name="tenantsContractInPlace" />
                                <span style={{ marginLeft: 10 }}>{t('screens:bids.data.tenantsContractInPlace')}</span>
                              </div>
                            </FormControl>
                            {values.tenantsContractInPlace ? (
                              <>
                                <FormControl cols={{ xs: 7, sm: 6, md: 6, lg: 6, xl: 6 }} field="contract">
                                  <UploadFile
                                    uploadList={uploadContract}
                                    setUploadList={setUploadContract}
                                    file={values.contract}
                                    accept=".pdf, .jpg, .jpeg, .png"
                                  />
                                </FormControl>

                              </>
                            ) : null}
                            <FormControl field="tenantsInsuranceOnFile" cols={{ xs: 24 }}>
                              <div style={{ paddingTop: 0 }}>
                                <Checkbox value="tenantsInsuranceOnFile" name="tenantsInsuranceOnFile" />
                                <span style={{ marginLeft: 10 }}>{t('screens:bids.data.tenantsInsuranceOnFile')}</span>
                              </div>
                            </FormControl>
                            {values.tenantsInsuranceOnFile ? (
                              <>
                                <FormControl cols={{ xs: 7, sm: 6, md: 6, lg: 6, xl: 6 }} field="tenantInsurance">
                                  <UploadFile
                                    uploadList={uploadInsurance}
                                    setUploadList={setUploadInsurance}
                                    file={values.tenantInsurance}
                                    accept=".pdf, .jpg, .jpeg, .png"
                                  />
                                </FormControl>
                              </>
                            ) : null} */}
                          </Row>
                          <Row>
                            <FormControl
                              cols={{ xs: 10, sm: 9, md: 9, lg: 9, xl: 9 }}
                              field="insuranceExpirationDate"
                              label={t('screens:bids.data.tenantsInsuranceExpirationDate')}
                            >
                              <DatePicker
                                name="insuranceExpirationDate"
                                format="MM/DD/Y"
                                placeholder={t('messages:select')}
                              />
                            </FormControl>
                          </Row>
                        </Box>
                      ),
                    },
                    // PROPERTY DATA
                    {
                      key: '2',
                      label: t('screens:bids.data.property'),
                      disabled: selectedTab !== '2',
                      children: (
                        <Box>
                          <Row>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="property.name"
                              error={(touched.property?.name && errors.property?.name) || errors.property?.name}
                              label={t('screens:bids.data.name')}
                              required
                            >
                              <Input name="property.name" autoComplete="off" />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="property.phone"
                              error={(touched.property?.phone && errors.property?.phone) || errors.property?.phone}
                              label={t('screens:bids.data.phoneNumber')}
                            >
                              <Input name="property.phone" autoComplete="off" maxLength={27} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="property.website"
                              error={
                                (touched.property?.website && errors.property?.website) || errors.property?.website
                              }
                              label={t('screens:bids.data.website')}
                            >
                              <Input name="property.website" autoComplete="off" />
                            </FormControl>
                          </Row>
                          <Divider orientation="left" orientationMargin="0">
                            <Text>{t('screens:bids.data.address')}</Text>
                          </Divider>
                          <Row>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={
                                touched.property?.address?.addressLineOne && errors.property?.address?.addressLineOne
                              }
                              field="property.address.addressLineOne"
                              label={t('screens:addresses.data.addressLineOne')}
                              required
                            >
                              <Input name="property.address.addressLineOne" autoComplete="off" maxLength={40} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={
                                touched.property?.address?.addressLineTwo && errors.property?.address?.addressLineTwo
                              }
                              field="property.address.addressLineTwo"
                              label={t('screens:addresses.data.addressLineTwo')}
                            >
                              <Input name="property.address.addressLineTwo" autoComplete="off" maxLength={40} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.property?.address?.city && errors.property?.address?.city}
                              field="property.address.city"
                              label={t('screens:addresses.data.city')}
                              required
                            >
                              <Input name="property.address.city" autoComplete="off" maxLength={16} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.property?.address?.state && errors.property?.address?.state}
                              field="property.address.state"
                              label={t('screens:addresses.data.state')}
                              required
                            >
                              <Input name="property.address.state" autoComplete="off" maxLength={21} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              field="property.address.zipCode"
                              label={t('screens:addresses.data.zipCode')}
                              error={touched.property?.address?.zipCode && errors.property?.address?.zipCode}
                              required
                            >
                              <Input name="property.address.zipCode" autoComplete="off" maxLength={13} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.property?.address?.country && errors.property?.address?.country}
                              field="property.address.country"
                              label={t('screens:addresses.data.country')}
                              required
                            >
                              <Select name="property.address.country" placeholder="Select">
                                {countries.map(item => {
                                  return (
                                    <Option key={item.name} value={item.name}>
                                      {item.description}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl
                              cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                              field="property.address.note"
                              error={(touched.note && errors.note) || errors.note}
                              label={t('screens:addresses.data.note')}
                            >
                              <Input name="property.address.note" autoComplete="off" maxLength={41} />
                            </FormControl>
                          </Row>
                        </Box>
                      ),
                    },
                    // OWNERSHIP DATA
                    {
                      key: '3',
                      label: t('screens:bids.data.ownership'),
                      disabled: selectedTab !== '3',
                      children: (
                        <Box>
                          <Row>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="ownership.name"
                              error={(touched.ownership?.name && errors.ownership?.name) || errors.ownership?.name}
                              label={t('screens:bids.data.ownershipName')}
                              required
                            >
                              <Input name="ownership.name" autoComplete="off" />
                            </FormControl>
                          </Row>
                          <Row>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="ownership.representativeCompany"
                              error={
                                (touched.ownership?.representativeCompany && errors.ownership?.representativeCompany) ||
                                errors.ownership?.representativeCompany
                              }
                              label={t('screens:bids.data.ownershipRepresentativeCompany')}
                            >
                              <Input name="ownership.representativeCompany" autoComplete="off" maxLength={27} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="ownership.representativeName"
                              error={
                                (touched.ownership?.representativeName && errors.ownership?.representativeName) ||
                                errors.ownership?.representativeName
                              }
                              label={t('screens:bids.data.ownershipRepresentativeName')}
                            >
                              <Input name="ownership.representativeName" autoComplete="off" maxLength={27} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="ownership.representativePhone"
                              error={
                                (touched.ownership?.representativePhone && errors.ownership?.representativePhone) ||
                                errors.ownership?.representativePhone
                              }
                              label={t('screens:bids.data.ownershipRepresentativePhone')}
                            >
                              <Input name="ownership.representativePhone" autoComplete="off" maxLength={27} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="ownership.representativeEmail"
                              error={
                                (touched.ownership?.representativeEmail && errors.ownership?.representativeEmail) ||
                                errors.ownership?.representativeEmail
                              }
                              label={t('screens:bids.data.ownershipRepresentativeEmail')}
                            >
                              <Input name="ownership.representativeEmail" autoComplete="off" maxLength={27} />
                            </FormControl>
                          </Row>
                          <Divider orientation="left" orientationMargin="0">
                            <Text>{t('screens:bids.data.address')}</Text>
                          </Divider>
                          <Row>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={
                                touched.ownership?.address?.addressLineOne && errors.ownership?.address?.addressLineOne
                              }
                              field="ownership.address.addressLineOne"
                              label={t('screens:addresses.data.addressLineOne')}
                              required
                            >
                              <Input name="ownership.address.addressLineOne" autoComplete="off" maxLength={40} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={
                                touched.ownership?.address?.addressLineTwo && errors.ownership?.address?.addressLineTwo
                              }
                              field="ownership.address.addressLineTwo"
                              label={t('screens:addresses.data.addressLineTwo')}
                            >
                              <Input name="ownership.address.addressLineTwo" autoComplete="off" maxLength={40} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.ownership?.address?.city && errors.ownership?.address?.city}
                              field="ownership.address.city"
                              label={t('screens:addresses.data.city')}
                              required
                            >
                              <Input name="ownership.address.city" autoComplete="off" maxLength={16} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.ownership?.address?.state && errors.ownership?.address?.state}
                              field="ownership.address.state"
                              label={t('screens:addresses.data.state')}
                              required
                            >
                              <Input name="ownership.address.state" autoComplete="off" maxLength={21} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              field="ownership.address.zipCode"
                              error={touched.ownership?.address?.zipCode && errors.ownership?.address?.zipCode}
                              label={t('screens:addresses.data.zipCode')}
                              required
                            >
                              <Input name="ownership.address.zipCode" autoComplete="off" maxLength={13} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.ownership?.address?.country && errors.ownership?.address?.country}
                              field="ownership.address.country"
                              label={t('screens:addresses.data.country')}
                              required
                            >
                              <Select name="ownership.address.country" placeholder="Select">
                                {countries.map(item => {
                                  return (
                                    <Option key={item.name} value={item.name}>
                                      {item.description}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl
                              cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                              field="ownership.address.note"
                              error={(touched.note && errors.note) || errors.note}
                              label={t('screens:addresses.data.note')}
                            >
                              <Input name="ownership.address.note" autoComplete="off" maxLength={41} />
                            </FormControl>
                          </Row>
                        </Box>
                      ),
                    },
                    // ARCHITECT DATA
                    {
                      key: '4',
                      label: t('screens:bids.data.architect'),
                      disabled: selectedTab !== '4',
                      children: (
                        <Box>
                          <Row>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="architect.companyName"
                              error={
                                (touched.architect?.companyName && errors.architect?.companyName) ||
                                errors.architect?.companyName
                              }
                              label={t('screens:bids.data.architectCompanyName')}
                            >
                              <Input name="architect.companyName" autoComplete="off" />
                            </FormControl>
                          </Row>
                          <Row>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="architect.representativeName"
                              error={
                                (touched.architect?.representativeName && errors.architect?.representativeName) ||
                                errors.architect?.representativeName
                              }
                              label={t('screens:bids.data.architectRepresentativeName')}
                            >
                              <Input name="architect.representativeName" autoComplete="off" maxLength={27} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="architect.representativePhone"
                              error={
                                (touched.architect?.representativePhone && errors.architect?.representativePhone) ||
                                errors.architect?.representativePhone
                              }
                              label={t('screens:bids.data.architectRepresentativePhone')}
                            >
                              <Input name="architect.representativePhone" autoComplete="off" maxLength={27} />
                            </FormControl>
                          </Row>
                          <Divider orientation="left" orientationMargin="0">
                            <Text>{t('screens:bids.data.address')}</Text>
                          </Divider>
                          <Row>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={
                                touched.architect?.address?.addressLineOne && errors.architect?.address?.addressLineOne
                              }
                              field="architect.address.addressLineOne"
                              label={t('screens:addresses.data.addressLineOne')}
                            >
                              <Input name="architect.address.addressLineOne" autoComplete="off" maxLength={40} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={
                                touched.architect?.address?.addressLineTwo && errors.architect?.address?.addressLineTwo
                              }
                              field="architect.address.addressLineTwo"
                              label={t('screens:addresses.data.addressLineTwo')}
                            >
                              <Input name="architect.address.addressLineTwo" autoComplete="off" maxLength={40} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.architect?.address?.city && errors.architect?.address?.city}
                              field="architect.address.city"
                              label={t('screens:addresses.data.city')}
                            >
                              <Input name="architect.address.city" autoComplete="off" maxLength={16} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.architect?.address?.state && errors.architect?.address?.state}
                              field="architect.address.state"
                              label={t('screens:addresses.data.state')}
                            >
                              <Input name="architect.address.state" autoComplete="off" maxLength={21} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              field="architect.address.zipCode"
                              label={t('screens:addresses.data.zipCode')}
                              error={touched.architect?.address?.zipCode && errors.architect?.address?.zipCode}
                            >
                              <Input name="architect.address.zipCode" autoComplete="off" maxLength={13} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.architect?.address?.country && errors.architect?.address?.country}
                              field="architect.address.country"
                              label={t('screens:addresses.data.country')}
                            >
                              <Select name="architect.address.country" placeholder="Select">
                                {countries.map(item => {
                                  return (
                                    <Option key={item.name} value={item.name}>
                                      {item.description}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl
                              cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                              field="architect.address.note"
                              error={(touched.note && errors.note) || errors.note}
                              label={t('screens:addresses.data.note')}
                            >
                              <Input name="architect.address.note" autoComplete="off" maxLength={41} />
                            </FormControl>
                          </Row>
                        </Box>
                      ),
                    },
                    // DESIGNER DATA
                    {
                      key: '5',
                      label: t('screens:bids.data.designer'),
                      disabled: selectedTab !== '5',
                      children: (
                        <Box>
                          <Row>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="designer.companyName"
                              error={
                                (touched.designer?.companyName && errors.designer?.companyName) ||
                                errors.designer?.companyName
                              }
                              label={t('screens:bids.data.designerCompanyName')}
                            >
                              <Input name="designer.companyName" autoComplete="off" />
                            </FormControl>
                          </Row>
                          <Row>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="designer.representativeName"
                              error={
                                (touched.designer?.representativeName && errors.designer?.representativeName) ||
                                errors.designer?.representativeName
                              }
                              label={t('screens:bids.data.designerRepresentativeName')}
                            >
                              <Input name="designer.representativeName" autoComplete="off" maxLength={27} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                              field="designer.representativePhone"
                              error={
                                (touched.designer?.representativePhone && errors.designer?.representativePhone) ||
                                errors.designer?.representativePhone
                              }
                              label={t('screens:bids.data.designerRepresentativePhone')}
                            >
                              <Input name="designer.representativePhone" autoComplete="off" maxLength={27} />
                            </FormControl>
                          </Row>
                          <Divider orientation="left" orientationMargin="0">
                            <Text>{t('screens:bids.data.address')}</Text>
                          </Divider>
                          <Row>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={
                                touched.designer?.address?.addressLineOne && errors.designer?.address?.addressLineOne
                              }
                              field="designer.address.addressLineOne"
                              label={t('screens:addresses.data.addressLineOne')}
                            >
                              <Input name="designer.address.addressLineOne" autoComplete="off" maxLength={40} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 24 }}
                              error={
                                touched.designer?.address?.addressLineTwo && errors.designer?.address?.addressLineTwo
                              }
                              field="designer.address.addressLineTwo"
                              label={t('screens:addresses.data.addressLineTwo')}
                            >
                              <Input name="designer.address.addressLineTwo" autoComplete="off" maxLength={40} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.designer?.address?.city && errors.designer?.address?.city}
                              field="designer.address.city"
                              label={t('screens:addresses.data.city')}
                            >
                              <Input name="designer.address.city" autoComplete="off" maxLength={16} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.designer?.address?.state && errors.designer?.address?.state}
                              field="designer.address.state"
                              label={t('screens:addresses.data.state')}
                            >
                              <Input name="designer.address.state" autoComplete="off" maxLength={21} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              field="designer.address.zipCode"
                              label={t('screens:addresses.data.zipCode')}
                              error={touched.designer?.address?.zipCode && errors.designer?.address?.zipCode}
                            >
                              <Input name="designer.address.zipCode" autoComplete="off" maxLength={13} />
                            </FormControl>
                            <FormControl
                              cols={{ xs: 12 }}
                              error={touched.designer?.address?.country && errors.designer?.address?.country}
                              field="designer.address.country"
                              label={t('screens:addresses.data.country')}
                            >
                              <Select name="designer.address.country" placeholder="Select">
                                {countries.map(item => {
                                  return (
                                    <Option key={item.name} value={item.name}>
                                      {item.description}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <FormControl
                              cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                              field="designer.address.note"
                              error={(touched.note && errors.note) || errors.note}
                              label={t('screens:addresses.data.note')}
                            >
                              <Input name="designer.address.note" autoComplete="off" maxLength={41} />
                            </FormControl>
                          </Row>
                        </Box>
                      ),
                    },
                  ]}
                />
              </Form>
            </Spin>
          </Modal>
        </>
      )}
    </Formik>
  );
}
