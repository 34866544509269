// UNIT VALUES LIST
export const unitList = [
  {
    id: 1,
    name: 'EACH',
  },
  {
    id: 2,
    name: 'SF',
  },
  {
    id: 3,
    name: 'LF',
  },
  {
    id: 4,
    name: 'SY',
  },
  {
    id: 5,
    name: 'BDS',
  },
];

