import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button as ButtonAntd,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import Button from '~/components/Button';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import PropTypes from 'prop-types';
import { ModalFooter } from '~/components/Modal';
import axios from 'axios';
import { useSelector } from 'react-redux';
import errorHandler from '~/Utils/errorHandler';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { List } from './styles';
import { formatPrice } from '~/Utils';
import { useContext } from 'react';
import { TableActions } from '~/components/Table';
import { set, sub } from 'date-fns';

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default function ModalProposal({ record, visible, loading, onClose, onSave }) {
  const { t } = useTranslation();
  const token = useSelector(state => state.auth.token);
  const [selectedModel, setSelectedModel] = useState('1');
  const [generalConditions, setGeneralConditions] = useState(0.0);
  const [generalConditionsPercentage, setGeneralConditionsPercentage] = useState(0.0);
  const [overheadAndProfit, setOverheadAndProfit] = useState(0.0);
  const [overheadAndProfitPercentage, setOverheadAndProfitPercentage] = useState(0.0);
  const [warehousing, setWarehousing] = useState(0.0);
  const [warehousingPercentage, setWarehousingPercentage] = useState(0.0);
  const [notes, setNotes] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);
  const [subTotal, setSubTotal] = useState(0.0);

  const calcSubTotal = () => {
    let total = 0.0;
    record.items.forEach(item => {
      if (item.materialValue === null) item.materialValue = 0.0;
      if (item.laborValue === null) item.laborValue = 0.0;
      if (item.quantity === null) item.quantity = 0;

      if (item.materialValue === 0 && item.laborValue === 0) {
        total += (item.estimateDescription.materialValue + item.estimateDescription.laborValue) * item.quantity;
      } else {
        total += (item.materialValue + item.laborValue) * item.quantity;
      }
    });
    setSubTotal(total);
  };

  const fetchRecords = async () => {
    setSelectedModel('1');
    setGeneralConditions(0.0);
    setOverheadAndProfit(0.0);
    setWarehousing(0.0);
    setNotes('');
    calcSubTotal();
  };

  const onChangeGeneralConditions = value => {
    setGeneralConditions(value);
    if (subTotal > 0 && subTotal !== null && subTotal !== undefined) {
      setGeneralConditionsPercentage((value * 100) / subTotal);
    }
  };

  const onChangeOverheadAndProfit = value => {
    setOverheadAndProfit(value);
    if (subTotal > 0 && subTotal !== null && subTotal !== undefined) {
      setOverheadAndProfitPercentage((value * 100) / subTotal);
    }
  };

  const onChangeWarehousing = value => {
    setWarehousing(value);
    if (subTotal > 0 && subTotal !== null && subTotal !== undefined) {
      setWarehousingPercentage((value * 100) / subTotal);
    }
  };

  const onChangeNotes = e => {
    setNotes(e.target.value);
  };

  const handleSend = () => {
    onSave({
      model: selectedModel,
      generalConditions: generalConditions,
      overheadAndProfit: overheadAndProfit,
      warehousing: warehousing,
      notes: notes,
      otherItems: dataSource,
    });
  };

  const showFile = record => {
    try {
      const estimateDTO = {
        estimateId: record.id,
        model: selectedModel,
        generalConditions: generalConditions,
        overheadAndProfit: overheadAndProfit,
        warehousing: warehousing,
        notes: notes,
        otherItems: dataSource,
      };
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/estimates/generateProposal`,
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        data: estimateDTO,
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        var win = window.open(url, '_blank');
        win.focus();
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleDelete = key => {
    const newData = dataSource.filter(item => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns = [
    {
      title: t('screens:proposals.data.code'),
      dataIndex: 'code',
      width: '100',
      editable: true,
    },
    {
      title: t('screens:proposals.data.description'),
      dataIndex: 'description',
      editable: true,
    },
    {
      title: t('screens:proposals.data.value'),
      dataIndex: 'value',
      width: '100',
      editable: true,
      render: value => formatPrice(value),
    },
    {
      title: t('screens:proposals.data.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <TableActions>
            <Popconfirm
              icon={false}
              title={t('messages:confirmDelete')}
              onConfirm={() => handleDelete(record.key)}
              okText={t('messages:yes')}
              cancelText={t('messages:no')}
            >
              <Button title={t('messages:delete')}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </TableActions>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      id: null,
      code: '0',
      description: 'Description',
      value: 0.0,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = row => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  return (
    <Modal
      width={650}
      closable={false}
      title={t('screens:proposals.title')}
      open={visible}
      onCancel={onClose}
      loading={loading}
      footer={
        <ModalFooter onOk={handleSend} okText="Send" loading={loading} onCancel={onClose} cancelColor="default" />
      }
    >
      <Spin spinning={loading}>
        <Row>
          <FormControl
            cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            field="generalConditions"
            label={t('screens:proposals.generalConditions')}
          >
            <Space.Compact block>
              <InputNumber
                addonBefore="$"
                name="generalConditions"
                value={generalConditions}
                formatter={value => `${parseFloat(value).toFixed(2)}`}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={onChangeGeneralConditions}
              />
              <InputNumber
                style={{ width: '120px' }}
                value={generalConditionsPercentage}
                min={0}
                max={1000}
                formatter={value => `${parseFloat(value).toFixed(2)}%`}
                parser={value => value.replace('%', '')}
                onChange={value => {
                  if (subTotal !== 0) {
                    onChangeGeneralConditions((subTotal * value) / 100);
                  }
                }}
              />
            </Space.Compact>
          </FormControl>
          <FormControl
            cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            field="overheadAndProfit"
            label={t('screens:proposals.overheadAndProfit')}
          >
            <Space.Compact block>
              <InputNumber
                addonBefore="$"
                name="overheadAndProfit"
                value={overheadAndProfit}
                formatter={value => `${parseFloat(value).toFixed(2)}`}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={onChangeOverheadAndProfit}
              />
              <InputNumber
                style={{ width: '120px' }}
                value={overheadAndProfitPercentage}
                min={0}
                max={1000}
                formatter={value => `${parseFloat(value).toFixed(2)}%`}
                parser={value => value.replace('%', '')}
                onChange={value => {
                  if (subTotal !== 0) {
                    onChangeOverheadAndProfit((subTotal * value) / 100);
                  }
                }}
              />
            </Space.Compact>
          </FormControl>
          <FormControl
            cols={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            field="warehousing"
            label={t('screens:proposals.warehousing')}
          >
            <Space.Compact block>
              <InputNumber
                addonBefore="$"
                name="warehousing"
                value={warehousing}
                formatter={value => `${parseFloat(value).toFixed(2)}`}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={onChangeWarehousing}
              />
              <InputNumber
                style={{ width: '120px' }}
                value={warehousingPercentage}
                min={0}
                max={1000}
                formatter={value => `${parseFloat(value).toFixed(2)}%`}
                parser={value => value.replace('%', '')}
                onChange={value => {
                  if (subTotal !== 0) {
                    onChangeWarehousing((subTotal * value) / 100);
                  }
                }}
              />
            </Space.Compact>
          </FormControl>
          <FormControl
            cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
            field="notes"
            label={t('screens:proposals.notes')}
          >
            <Input.TextArea name="notes" defaultValue={notes} showCount maxLength={5000} onChange={onChangeNotes} />
          </FormControl>
          <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }} field="others">
            <div>
              <ButtonAntd onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                {t('screens:proposals.addAItem')}
              </ButtonAntd>
              <Table
                bordered
                size="small"
                title={() => t('screens:proposals.otherItems')}
                scroll={{ y: 170, x: 'max-content' }}
                components={components}
                dataSource={dataSource}
                columns={columns}
              />
            </div>
          </FormControl>
          <FormControl field="model" cols={{ xs: 24 }} label={t('screens:proposals.proposalModel')}>
            <List>
              <li>
                <Select
                  defaultValue={selectedModel}
                  style={{ width: 120 }}
                  onChange={value => {
                    setSelectedModel(value);
                  }}
                  options={[
                    { value: '1', label: t('screens:proposals.modelOne') },
                    { value: '2', label: t('screens:proposals.modelTwo') },
                  ]}
                />
              </li>
              <li>
                <Tooltip title={t('messages:view')}>
                  <ButtonAntd icon={<EyeOutlined />} onClick={() => showFile(record)} />
                </Tooltip>
              </li>
            </List>
          </FormControl>
        </Row>
      </Spin>
    </Modal>
  );
}

ModalProposal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onLoad: PropTypes.func,
};

ModalProposal.defaultProps = {
  visible: false,
  loading: false,
};
