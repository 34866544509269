import React, { useState } from 'react';
import { Col, Divider, Modal, Spin, Typography } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import moment from 'moment';
import { createCost, updateCost } from '~/services/hooks/costs';
import { uploadFiles } from '~/services/hooks/files';
import UploadFile from '~/components/UploadFile';

const { Text } = Typography;
const style = {
  divider: { paddingLeft: '8px', marginBottom: '10px', fontSize: `16px` },
};

export default function CostsForm({
  selectedRecord,
  visible,
  costTypes,
  changeOrders,
  project,
  onClose,
  onUpdateItem,
  onInsertItem,
}) {
  const initialValues = {
    id: null,
    description: '',
    costType: costTypes[0],
    invoice: null,
    value: 0,
    date: moment().format('YYYY-MM-DD'),
    employee: '',
    notes: '',
    receipt: null,
    project: project,
    changeOrder: null,
  };

  const { Option } = Select;
  const { t } = useTranslation();
  const recordData = selectedRecord || initialValues;
  const [loading, setLoading] = useState(false);
  const [uploadList, setUploadList] = useState([]);

  const resetForm = () => {
    setUploadList([]);
  };
  
  const handleSave = async values => {
    if (uploadList.length !== 0) {
      const data = await uploadFiles(uploadList);
      values.receipt = data[0];
    }
    values.date = moment(values.date).format('YYYY-MM-DD HH:mm:ss');
    setLoading(true);
    if (values.id !== null) {
      const data = await updateCost(values);
      onUpdateItem(data);
    } else {
      const data = await createCost(values);
      onInsertItem(data);
    }
    resetForm();
    onClose();
    setLoading(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={recordData}
      onSubmit={handleSave}
      // validationSchema={costschema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, touched, values }) => (
        <Modal
          width="900px"
          description={
            selectedRecord !== null ? t('screens:workbook.costs.btnEdit') : t('screens:workbook.costs.btnNew')
          }
          onCancel={onClose}
          afterClose={resetForm}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Text style={style.divider}>{t('screens:workbook.costs.data.information')}</Text>
              </Row>
              <Row>
                <Col span={16}>
                  {/* DESCRIPTION */}
                  <FormControl
                    field="description"
                    label={t('screens:workbook.costs.data.description')}
                    required
                    error={(touched.description && errors.description) || errors.description}
                  >
                    <Input name="description" />
                  </FormControl>
                </Col>
                <Col span={8}>
                  {/* TYPE */}
                  <FormControl
                    field="costType.id"
                    label={t('screens:workbook.costs.data.type')}
                    required
                    error={(touched.costType?.id && errors.costType?.id) || errors.costType?.id}
                  >
                    <Select
                      name="costType.id"
                      id="costType.id"
                      placeholder="Select"
                      value={values.costType?.id}
                      onChange={value => {
                        values.costType = costTypes.find(item => item.id === value);
                      }}
                    >
                      {costTypes?.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {/* VALUE */}
                  <FormControl field="value" label={t('screens:workbook.costs.data.value')} error={errors.value}>
                    <InputCurrency
                      defaultValue={0}
                      formatter={value =>
                        parseFloat(value.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      name="value"
                      currency="$"
                      number={true}
                      style={{ textAlign: 'right' }}
                    />
                  </FormControl>
                </Col>
                <Col span={8}>
                  {/* DATE */}
                  <FormControl field="date" label={t('screens:workbook.costs.data.date')} error={errors.date}>
                    <DatePicker name="date" format="YYYY/MM/DD" placeholder={t('messages:select')} />
                  </FormControl>
                </Col>
                <Col span={8}>
                  {/* EMPLOYEE */}
                  <FormControl
                    field="employee"
                    label={t('screens:workbook.costs.data.employee')}
                    required
                    error={(touched.employee && errors.employee) || errors.employee}
                  >
                    <Input name="employee" />
                  </FormControl>
                </Col>
                <Col span={8}>
                  {/* CHANGE ORDER */}
                  <FormControl
                    field="changeOrder.id"
                    label={t('screens:workbook.costs.data.change_order_id')}
                    required
                    error={(touched.changeOrder?.id && errors.changeOrder?.id) || errors.changeOrder?.id}
                  >
                    <Select
                      name="changeOrder.id"
                      id="changeOrder.id"
                      placeholder="Select"
                      value={values?.changeOrder?.id}
                      onChange={value => {
                        values.changeOrder = changeOrders.find(item => item.id === value);
                      }}
                    >
                      {changeOrders?.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {/* NOTES */}
                  <FormControl
                    field="notes"
                    label={t('screens:workbook.costs.data.notes')}
                    error={(touched.notes && errors.notes) || errors.notes}
                  >
                    <Input.TextArea showCount maxLength={250} name="notes" />
                  </FormControl>
                </Col>
              </Row>

              <Divider orientation="left" orientationMargin="0">
                <Text>{t('screens:workbook.costs.data.receipt')}</Text>
              </Divider>
              <Row>
                <Col span={6}>
                  <FormControl field="payment_receipt">
                    <UploadFile uploadList={uploadList} setUploadList={setUploadList} file={values.receipt} />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
