import React, { useState } from 'react';
import { EditOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Col, Modal, Spin, Typography, Table, Space, Descriptions, Card, Button, Popover } from 'antd';
import { Formik } from 'formik';
import { Form, Input, DatePicker } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { formatPrice, formatPercent } from '~/Utils';
import moment from 'moment';
import { createPayAppsClient, updatePayAppsClient } from '~/services/hooks/payAppsClients';
import FormItem from './formItem';

const { Text } = Typography;
const { Title } = Typography;

export default function PayAppsClientsForm({
  selectedRecord,
  project,
  customer,
  proposalClient,
  visible,
  viewOnly,
  onClose,
  onUpdateItem,
  onInsertItem,
}) {
  const initialValues = {
    id: null,
    project: project,
    applDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    periodTo: moment().format('YYYY-MM-DD HH:mm:ss'),
    costCode: '',
    contractFor: '',
    signatureDate: proposalClient?.proposalSignature?.date,
    total: 0.0,
    items: [],
  };

  const { t } = useTranslation();
  const recordData = selectedRecord || initialValues;
  const [loading, setLoading] = useState(false);
  const [totalScheduleValue, setTotalScheduleValue] = useState(0);
  const [totalPreviousApplication, setTotalPreviousApplication] = useState(0);
  const [totalWorkInPlace, setTotalWorkInPlace] = useState(0);
  const [totalStoredMaterials, setTotalStoredMaterials] = useState(0);
  const [totalToDate, setTotalToDate] = useState(0);
  const [totalBalanceToFinish, setTotalBalanceToFinish] = useState(0);
  const [totalPercentageComplete, setTotalPercentageComplete] = useState(0);
  const [totalRetainage, setTotalRetainage] = useState(0);
  const [totalEarnedLessRetainage, setTotalEarnedLessRetainage] = useState(0);
  const [totalCompletedAndStored, setTotalCompletedAndStored] = useState(0);
  const [totalChangeOrders, setTotalChangeOrders] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const proposalClientItemsTotal = proposalClient.items.reduce((acc, item) => acc + item.total, 0);
  const proposalClientOtherItemsTotal = proposalClient.otherItems.reduce((acc, item) => acc + item.value, 0);
  const originalContractSum = proposalClientItemsTotal + proposalClientOtherItemsTotal;
  const contractSumToDate = originalContractSum + totalChangeOrders;
  const balanceToFinish = contractSumToDate - totalCompletedAndStored;
  const currentPaymentDue = recordData?.total;

  const orderItems = items => {
    if (!items) return [];
    const items1 = items.filter(
      // PROPOSAL CLIENT ITEM
      item =>
        item.proposalClientItem !== null &&
        // item.proposalOtherItem === null &&
        item.generalConditions === false &&
        item.overheadAndProfit === false
      // item.changeOrderClient === null
    );
    const items2 = items.filter(
      // PROPOSAL OTHER ITEM
      item =>
        // Item.proposalClientItem === null &&
        item.proposalOtherItem !== null && item.generalConditions === false && item.overheadAndProfit === false
      // item.changeOrderClient === false
    );
    const items3 = items.filter(
      // GENERAL CONDITIONS
      item => item.proposalOtherItem === null && item.generalConditions === true && item.overheadAndProfit === false
      // item.changeOrderClient === false
    );
    const items4 = items.filter(
      // OVERHEAD AND PROFIT
      item => item.proposalOtherItem === null && item.generalConditions === false && item.overheadAndProfit === true
      // item.changeOrderClient === false
    );
    const items5 = items.filter(
      // CHANGE ORDER CLIENT
      item =>
        // item.proposalOtherItem === null &&
        item.generalConditions === false && item.overheadAndProfit === false && item.changeOrderClient !== null
    );
    const orderedItems = [...items1, ...items2, ...items3, ...items4, ...items5];
    return orderedItems;
  };

  const removeValueInEspecialItems = values => {
    values.forEach(item => {
      if (item.generalConditions === true || item.overheadAndProfit === true) {
        item.proposalClientItem = null;
      }
    });
  };

  recordData.items = orderItems(recordData?.items);

  const calculateTotals = valuesItems => {
    let totScheduleValue = 0;
    let totPreviousApplication = 0;
    let totWorkInPlace = 0;
    let totStoredMaterials = 0;
    let totToDate = 0;
    let totPercentageComplete = 0;
    let totBalanceToFinish = 0;
    let totRetainage = 0;
    let totChangeOrders = 0;

    valuesItems.forEach(item => {
      totScheduleValue +=
        item.proposalClientItem !== null
          ? item.proposalClientItem.total
          : item.proposalOtherItem !== null
          ? item.proposalOtherItem.value
          : item.changeOrderClient !== null
          ? item.changeOrderClient?.priceInfo?.items?.reduce((a, b) => a + b.unitCost * b.quantity, 0)
          : 0;

      totPreviousApplication += item.previousApplications;
      totWorkInPlace += item.workInPlace;
      totStoredMaterials += item.storedMaterials;
    });

    totToDate = totPreviousApplication + totWorkInPlace + totStoredMaterials;
    totRetainage = totToDate * 0.1;
    totBalanceToFinish = totScheduleValue - totalToDate;
    totPercentageComplete = totalToDate / totScheduleValue;

    // set values to state
    setTotalScheduleValue(totScheduleValue);
    setTotalPreviousApplication(totPreviousApplication);
    setTotalWorkInPlace(totWorkInPlace);
    setTotalStoredMaterials(totStoredMaterials);
    setTotalToDate(totToDate);
    setTotalRetainage(totRetainage);
    setTotalBalanceToFinish(totBalanceToFinish);
    setTotalPercentageComplete(totPercentageComplete);
    setTotalEarnedLessRetainage(totToDate - totRetainage);
    setTotalCompletedAndStored(totToDate);
    setTotalChangeOrders(totChangeOrders);
  };

  const handleSave = async values => {
    setLoading(true);
    if (values.items) removeValueInEspecialItems(values.items);
    values.total = totalWorkInPlace * 0.9;
    values.invoice = {
      id: null,
      date: moment().format('YYYY-MM-DD HH:mm:ss'),
      paymentDate: null,
      attachments: [],
    };
    if (values.id !== null) {
      const data = await updatePayAppsClient(values);
      onUpdateItem(data);
    } else {
      values.items.forEach(item => {
        item.id = null;
      });
      const data = await createPayAppsClient(values);
      onInsertItem(data);
    }
    onClose();
    setLoading(false);
  };

  const handleEdit = record => {
    setSelectedItem(record);
    setShowModal(true);
  };

  const tableColumns = [
    {
      title: t('screens:payAppClients.items.itemId'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: { showTitle: false },
      width: '50px',
      align: 'center',
    },
    {
      title: t('screens:payAppClients.items.description'),
      dataIndex: 'proposalClientItem',
      key: 'proposalClientItem.description',
      ellipsis: { showTitle: false },
      align: 'center',
      width: '250px',
      render: proposalClientItem => <>{proposalClientItem?.description}</>,
    },
    {
      title: t('screens:payAppClients.items.scheduledValue'),
      dataIndex: 'proposalClientItem',
      key: 'proposalClientItem.total',
      ellipsis: { showTitle: false },
      align: 'center',
      render: proposalClientItem => <>{formatPrice(proposalClientItem?.total)}</>,
    },
    {
      title: t('screens:payAppClients.items.previousApplications'),
      dataIndex: 'previousApplications',
      key: 'previousApplications',
      ellipsis: { showTitle: false },
      align: 'center',
      render: previousApplications => <>{formatPrice(previousApplications)}</>,
    },
    {
      title: t('screens:payAppClients.items.workInPlace'),
      dataIndex: 'workInPlace',
      key: 'workInPlace',
      ellipsis: { showTitle: false },
      align: 'center',
      render: workInPlace => <>{formatPrice(workInPlace)}</>,
    },
    {
      title: t('screens:payAppClients.items.storedMaterials'),
      dataIndex: 'storedMaterials',
      key: 'storedMaterials',
      ellipsis: { showTitle: false },
      align: 'center',
      render: storedMaterials => <>{formatPrice(storedMaterials)}</>,
    },
    {
      title: t('screens:payAppClients.items.totalToDate'),
      key: 'totalToDate',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => <>{formatPrice(record.previousApplications + record.workInPlace + record.storedMaterials)}</>,
    },
    {
      title: t('screens:payAppClients.items.percentage'),
      key: 'percentage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>
          {formatPercent(
            record.proposalClientItem?.total > 0
              ? (record.previousApplications + record.workInPlace + record.storedMaterials) /
                  record.proposalClientItem.total
              : 0
          )}
        </>
      ),
    },
    {
      title: t('screens:payAppClients.items.balanceToFinish'),
      key: 'balanceToFinish',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>
          {formatPrice(
            record.proposalClientItem?.total -
              (record.previousApplications + record.workInPlace + record.storedMaterials)
          )}
        </>
      ),
    },
    {
      title: t('screens:payAppClients.items.retainage'),
      key: 'retainage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>{formatPrice((record.previousApplications + record.workInPlace + record.storedMaterials) * 0.1)}</>
      ),
    },
    {
      title: t('screens:estimate.actions'),
      key: 'actions',
      align: 'center',
      width: '70px',
      render: record => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            title={t('screens:estimate.editItem')}
          />
        </Space>
      ),
    },
  ];

  const tableColumnsOtherItems = [
    {
      title: t('screens:payAppClients.items.itemId'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: { showTitle: false },
      width: '50px',
      align: 'center',
    },
    {
      title: t('screens:payAppClients.items.description'),
      dataIndex: 'proposalOtherItem',
      key: 'proposalOtherItem.description',
      ellipsis: { showTitle: false },
      align: 'center',
      width: '250px',
      render: proposalOtherItem => <>{proposalOtherItem?.description}</>,
    },
    {
      title: t('screens:payAppClients.items.scheduledValue'),
      dataIndex: 'proposalOtherItem',
      key: 'proposalOtherItem.value',
      ellipsis: { showTitle: false },
      align: 'center',
      render: proposalOtherItem => <>{formatPrice(proposalOtherItem?.value)}</>,
    },
    {
      title: t('screens:payAppClients.items.previousApplications'),
      dataIndex: 'previousApplications',
      key: 'previousApplications',
      ellipsis: { showTitle: false },
      align: 'center',
      render: previousApplications => <>{formatPrice(previousApplications)}</>,
    },
    {
      title: t('screens:payAppClients.items.workInPlace'),
      dataIndex: 'workInPlace',
      key: 'workInPlace',
      ellipsis: { showTitle: false },
      align: 'center',
      render: workInPlace => <>{formatPrice(workInPlace)}</>,
    },
    {
      title: t('screens:payAppClients.items.storedMaterials'),
      dataIndex: 'storedMaterials',
      key: 'storedMaterials',
      ellipsis: { showTitle: false },
      align: 'center',
      render: storedMaterials => <>{formatPrice(storedMaterials)}</>,
    },
    {
      title: t('screens:payAppClients.items.totalToDate'),
      key: 'totalToDate',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => <>{formatPrice(record.previousApplications + record.workInPlace + record.storedMaterials)}</>,
    },
    {
      title: t('screens:payAppClients.items.percentage'),
      key: 'percentage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>
          {formatPercent(
            record.proposalOtherItem?.value > 0
              ? (record.previousApplications + record.workInPlace + record.storedMaterials) /
                  record.proposalOtherItem.value
              : 0
          )}
        </>
      ),
    },
    {
      title: t('screens:payAppClients.items.balanceToFinish'),
      key: 'balanceToFinish',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>
          {formatPrice(
            record.proposalOtherItem?.value -
              (record.previousApplications + record.workInPlace + record.storedMaterials)
          )}
        </>
      ),
    },
    {
      title: t('screens:payAppClients.items.retainage'),
      key: 'retainage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>{formatPrice((record.previousApplications + record.workInPlace + record.storedMaterials) * 0.1)}</>
      ),
    },
    {
      title: t('screens:estimate.actions'),
      key: 'actions',
      align: 'center',
      width: '70px',
      render: record => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            title={t('screens:estimate.editItem')}
          />
        </Space>
      ),
    },
  ];

  const tableColumnsSummary = [
    {
      title: t('screens:payAppClients.items.itemId'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: { showTitle: false },
      width: '50px',
      align: 'center',
    },
    {
      title: t('screens:payAppClients.items.description'),
      key: 'proposalClientItem.description',
      ellipsis: { showTitle: false },
      align: 'center',
      width: '250px',
      render: record => (
        <>{(record.generalConditions && 'General Conditions') || (record.overheadAndProfit && 'Overhead and profit')}</>
      ),
    },
    {
      title: t('screens:payAppClients.items.scheduledValue'),
      dataIndex: 'proposalClientItem',
      key: 'proposalClientItem.total',
      ellipsis: { showTitle: false },
      align: 'center',
      render: proposalClientItem => <>{formatPrice(proposalClientItem?.total)}</>,
    },
    {
      title: t('screens:payAppClients.items.previousApplications'),
      dataIndex: 'previousApplications',
      key: 'previousApplications',
      ellipsis: { showTitle: false },
      align: 'center',
      render: previousApplications => <>{formatPrice(previousApplications)}</>,
    },
    {
      title: t('screens:payAppClients.items.workInPlace'),
      dataIndex: 'workInPlace',
      key: 'workInPlace',
      ellipsis: { showTitle: false },
      align: 'center',
      render: workInPlace => <>{formatPrice(workInPlace)}</>,
    },
    {
      title: t('screens:payAppClients.items.storedMaterials'),
      dataIndex: 'storedMaterials',
      key: 'storedMaterials',
      ellipsis: { showTitle: false },
      align: 'center',
      render: storedMaterials => <>{formatPrice(storedMaterials)}</>,
    },
    {
      title: t('screens:payAppClients.items.totalToDate'),
      key: 'totalToDate',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => <>{formatPrice(record.previousApplications + record.workInPlace + record.storedMaterials)}</>,
    },
    {
      title: t('screens:payAppClients.items.percentage'),
      key: 'percentage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>
          {formatPercent(
            record.proposalClientItem?.total > 0
              ? (record.previousApplications + record.workInPlace + record.storedMaterials) /
                  record.proposalClientItem.total
              : 0
          )}
        </>
      ),
    },
    {
      title: t('screens:payAppClients.items.balanceToFinish'),
      key: 'balanceToFinish',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>
          {formatPrice(
            record.proposalClientItem?.total -
              (record.previousApplications + record.workInPlace + record.storedMaterials)
          )}
        </>
      ),
    },
    {
      title: t('screens:payAppClients.items.retainage'),
      key: 'retainage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>{formatPrice((record.previousApplications + record.workInPlace + record.storedMaterials) * 0.1)}</>
      ),
    },
    {
      title: t('screens:estimate.actions'),
      key: 'actions',
      align: 'center',
      width: '70px',
      render: record => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            title={t('screens:estimate.editItem')}
          />
        </Space>
      ),
    },
  ];

  const tableChangeOrderItems = [
    {
      title: t('screens:payAppClients.items.itemId'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: { showTitle: false },
      width: '50px',
      align: 'center',
    },
    {
      title: t('screens:payAppClients.items.description'),
      dataIndex: 'changeOrderClient',
      key: 'changeOrderClient.title',
      ellipsis: { showTitle: false },
      align: 'center',
      width: '250px',
      render: changeOrderClient => {
        // list of items in change order
        const items = changeOrderClient.priceInfo.items;
        return (
          <>
            {changeOrderClient.title}
            <Popover
              content={items.map(item => (
                <div>{item.description}</div>
              ))}
            >
              <Button type="link" icon={<UnorderedListOutlined />} />
            </Popover>
          </>
        );
      },
    },
    {
      title: t('screens:payAppClients.items.scheduledValue'),
      key: 'value',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        const totalItemsValue = record.changeOrderClient.priceInfo.items.reduce(
          (acc, item) => acc + item.unitCost * item.quantity,
          0
        );
        return <>{formatPrice(totalItemsValue)}</>;
      },
    },
    {
      title: t('screens:payAppClients.items.previousApplications'),
      dataIndex: 'previousApplications',
      key: 'previousApplications',
      ellipsis: { showTitle: false },
      align: 'center',
      render: previousApplications => <>{formatPrice(previousApplications)}</>,
    },
    {
      title: t('screens:payAppClients.items.workInPlace'),
      dataIndex: 'workInPlace',
      key: 'workInPlace',
      ellipsis: { showTitle: false },
      align: 'center',
      render: workInPlace => <>{formatPrice(workInPlace)}</>,
    },
    {
      title: t('screens:payAppClients.items.storedMaterials'),
      dataIndex: 'storedMaterials',
      key: 'storedMaterials',
      ellipsis: { showTitle: false },
      align: 'center',
      render: storedMaterials => <>{formatPrice(storedMaterials)}</>,
    },
    {
      title: t('screens:payAppClients.items.totalToDate'),
      key: 'totalToDate',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => <>{formatPrice(record.previousApplications + record.workInPlace + record.storedMaterials)}</>,
    },
    {
      title: t('screens:payAppClients.items.percentage'),
      key: 'percentage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        const totalItemsValue = record.changeOrderClient.priceInfo.items.reduce(
          (acc, item) => acc + item.unitCost * item.quantity,
          0
        );
        return formatPercent(
          totalItemsValue > 0
            ? (record.previousApplications + record.workInPlace + record.storedMaterials) / totalItemsValue
            : 0
        );
      },
    },
    {
      title: t('screens:payAppClients.items.balanceToFinish'),
      key: 'balanceToFinish',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => {
        const totalItemsValue = record.changeOrderClient.priceInfo.items.reduce(
          (acc, item) => acc + item.unitCost * item.quantity,
          0
        );
        return formatPrice(
          totalItemsValue - (record.previousApplications + record.workInPlace + record.storedMaterials)
        );
      },
    },
    {
      title: t('screens:payAppClients.items.retainage'),
      key: 'retainage',
      ellipsis: { showTitle: false },
      align: 'center',
      render: record => (
        <>{formatPrice((record.previousApplications + record.workInPlace + record.storedMaterials) * 0.1)}</>
      ),
    },
    {
      title: t('screens:estimate.actions'),
      key: 'actions',
      align: 'center',
      width: '70px',
      render: record => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            title={t('screens:estimate.editItem')}
          />
        </Space>
      ),
    },
  ];

  const costsSchema = Yup.object().shape({
    architectsProjectNumber: Yup.number().required(),
    contractFor: Yup.string().required(),
    costCode: Yup.string().required(),
    periodTo: Yup.date().required(),
    applDate: Yup.date().required(),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={recordData}
        onSubmit={handleSave}
        validationSchema={costsSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, isSubmitting, submitForm, resetForm, touched, setFieldValue, values, setValues }) => (
          <>
            <Modal
              width="1100px"
              description={
                selectedRecord !== null ? t('screens:payAppsClients.btnEdit') : t('screens:payAppsClients.btnNew')
              }
              title={<Title level={4}>APPLICATION AND CERTIFICATION FOR PAYMENT</Title>}
              onCancel={onClose}
              afterClose={resetForm}
              open={visible}
              loading={loading || isSubmitting}
              footer={
                <ModalFooter
                  onOk={submitForm}
                  loading={loading || isSubmitting}
                  onCancel={onClose}
                  cancelColor="default"
                />
              }
            >
              <Spin spinning={loading || isSubmitting}>
                <Form disabled={viewOnly}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Descriptions
                          layout="vertical"
                          bordered
                          column={3}
                        >
                          <Descriptions.Item label="TO">
                            <Text strong>{recordData.project.ownership.name}</Text>
                            <br />

                            {`${recordData.project.ownership.addressLineOne} ${
                              recordData.project.ownership.addressLineTwo
                                ? ', ' + recordData.project.ownership.addressLineTwo
                                : ''
                            }`}
                            <br />

                            {`${recordData.project.ownership.city}, ${recordData.project.ownership.zipCode}`}
                          </Descriptions.Item>
                          <Descriptions.Item label="PROJECT">
                            <Text strong>{recordData.project.description}</Text>
                            <br />

                            {`${recordData.project.property.address.addressLineOne} ${
                              recordData.project.property.address.addressLineTwo
                                ? ', ' + recordData.project.property.address.addressLineTwo
                                : ''
                            }`}
                            <br />

                            {`${recordData.project.property.address.city}, ${recordData.project.property.address.state} ${recordData.project.property.address.zipCode}`}
                          </Descriptions.Item>
                          <Descriptions.Item label="APPLICATION #">{recordData.id}</Descriptions.Item>
                          <Descriptions.Item label="FROM">
                            <Text strong>{customer?.company?.name}</Text>
                            <br />

                            {customer?.company?.id === 1 ? (
                              <>
                                11501 Lake Underhill Road <br /> Orlando FL 32825
                              </>
                            ) : (
                              <>
                                7362 Futures Dr Suite 12B <br /> Orlando, Fl 32819
                              </>
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item label="VIA (ARCHITECT)">
                            <Text strong>{recordData.project.architect?.companyName}</Text>
                            <br />

                            {`${recordData.project.architect?.address.addressLineOne} ${
                              recordData.project.architect?.address.addressLineTwo
                                ? ', ' + recordData.project.architect?.address.addressLineTwo
                                : ''
                            }`}
                            <br />
                            {`${recordData.project.architect?.address.city}, ${recordData.project.architect?.address.state} ${recordData.project.architect?.address.zipCode}`}
                          </Descriptions.Item>
                          <Descriptions.Item label="PERIOD TO">
                            <FormControl field="periodTo" error={errors.date} required>
                              <DatePicker
                                name="periodTo"
                                format="YYYY/MM/DD"
                                placeholder={t('messages:select')}
                                style={{ width: 150 }}
                              />
                            </FormControl>
                          </Descriptions.Item>
                          <Descriptions.Item label="ARCHITECT'S PROJECT Nº">
                            <FormControl field="architectsProjectNumber" error={errors.architectsProjectNumber}>
                              <Input
                                name="architectsProjectNumber"
                                placeholder={t('screens:payAppsClients.architectsProjectNumberPlaceholder')}
                                maxLength={10}
                                style={{ width: 150 }}
                              />
                            </FormControl>
                          </Descriptions.Item>
                          <Descriptions.Item label="CONTRACT FOR">
                            <FormControl
                              field="contractFor"
                              required
                              error={(touched.contractFor && errors.contractFor) || errors.contractFor}
                            >
                              <Input
                                name="contractFor"
                                placeholder={t('screens:payAppsClients.contractForPlaceholder')}
                                style={{ width: 250 }}
                              />
                            </FormControl>
                          </Descriptions.Item>
                          <Descriptions.Item label="APPL. DATE">
                            <FormControl field="applDate" error={errors.date}>
                              <DatePicker
                                name="applDate"
                                format="YYYY/MM/DD"
                                placeholder={t('messages:select')}
                                style={{ width: 150 }}
                              />
                            </FormControl>
                          </Descriptions.Item>
                          <Descriptions.Item label="COST CODE">
                            <FormControl field="costCode" error={errors.costCode}>
                              <Input
                                name="costCode"
                                placeholder={t('screens:payAppsClients.costCodePlaceholder')}
                                style={{ width: 150 }}
                              />
                            </FormControl>
                          </Descriptions.Item>
                          <Descriptions.Item label="JOB N">{recordData.project.id}</Descriptions.Item>
                          <Descriptions.Item label="JOB NAME">{recordData.project.description}</Descriptions.Item>
                        </Descriptions>
                      </Space>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12} style={{ paddingTop: '30px' }}>
                      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Descriptions
                          title="CONTRACTOR'S APPLICATION FOR PAYMENT"
                          layout="vertical"
                          bordered
                          column={1}
                          contentStyle={{ width: 120 }}
                        >
                          <Descriptions.Item label="OBSERVATION">
                            The undersigned Subcontractor/Vendor certifies that to the best of his knowledge information
                            and belief the Work covered by this Application for Payment has been completed in accordance
                            with the Contract Documents, that all amounts have been paid by the contractor for Work for
                            which previous Certificates for Payment were issued and payments received from the Owner,
                            and that current payment shown herein is now due.
                            <br />
                            <br />
                            By:_____________________________ Date: {moment(recordData.applDate).format('YYYY/MM/DD')}
                          </Descriptions.Item>
                        </Descriptions>
                        <Card
                          title={<Text style={{ fontSize: 14 }}>APPROVED FOR PAYMENT</Text>}
                          bordered={true}
                          bodyStyle={{ fontSize: 14, backgroundColor: '#ffffff' }}
                          style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                        >
                          State of: _______________ County of: _______________ Subscribed and sworn to before me this:
                          _______________ day of: ____________ Notary Public:_______________ My Commission Expires:
                          _______________
                        </Card>

                        <Card
                          title={<Text style={{ fontSize: 14 }}>APPROVED FOR PAYMENT</Text>}
                          bordered={true}
                          bodyStyle={{ fontSize: 14, backgroundColor: '#ffffff' }}
                          style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                        >
                          By:_____________________________(Superintendent) Date:_______________
                        </Card>

                        <Card
                          title={<Text style={{ fontSize: 14 }}>APPROVED FOR PAYMENT</Text>}
                          bordered={true}
                          bodyStyle={{ fontSize: 14, backgroundColor: '#ffffff' }}
                          style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                        >
                          By:_____________________________(Project Manager) Date:_______________
                          <br />
                          Pay Upon Receipt of Owner Draw Request No:___________
                        </Card>
                      </Space>
                    </Col>
                    <Col span={12} style={{ paddingTop: '30px' }}>
                      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Descriptions title="&nbsp;" layout="horizontal" bordered column={1}>
                          <Descriptions.Item label="1. ORIGINAL CONTRACT">
                            {formatPrice(originalContractSum)}
                          </Descriptions.Item>
                          <Descriptions.Item label="2. NET CHANGE BY CHANGE ORDERS">
                            {formatPrice(totalChangeOrders)}
                          </Descriptions.Item>
                          <Descriptions.Item label="3. CONTRACT SUM TO DATE">
                            {formatPrice(contractSumToDate)}
                          </Descriptions.Item>
                          <Descriptions.Item label="4. TOTAL COMPLETED & STORED TO DATE">
                            {formatPrice(totalCompletedAndStored)}
                          </Descriptions.Item>
                        </Descriptions>
                        <Card
                          title={<Text style={{ fontSize: 14 }}>5. RETAINAGE</Text>}
                          bordered={true}
                          bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                          style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                        >
                          <Descriptions
                            layout="horizontal"
                            bordered
                            column={1}
                            contentStyle={{ width: 120 }}
                            labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40 }}
                            size="middle"
                          >
                            <Descriptions.Item label="5.1. 10	% of Completed Work">
                              {formatPrice(totalWorkInPlace * 0.1 )}
                            </Descriptions.Item>
                            <Descriptions.Item label="5.2. 10	% of Stored Material">
                              {formatPrice(totalStoredMaterials * 0.1)}
                            </Descriptions.Item>
                            <Descriptions.Item label="TOTAL RETAINAGE">{formatPrice(totalRetainage)}</Descriptions.Item>
                          </Descriptions>
                        </Card>
                        <Descriptions layout="horizontal" bordered column={1} contentStyle={{ width: 120 }}>
                          <Descriptions.Item label="6. TOTAL EARNED LESS RETAINAGE">
                            {formatPrice(totalEarnedLessRetainage)}
                          </Descriptions.Item>
                          <Descriptions.Item label="7. LESS PREVIOUS CERTIFICATES FOR PAYMENT">
                            {formatPrice(totalPreviousApplication * 0.9)}
                          </Descriptions.Item>
                          <Descriptions.Item label="8. CURRENT PAYMENT DUE">
                            {formatPrice(currentPaymentDue || 0)}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions layout="horizontal" bordered column={1} contentStyle={{ width: 120 }}>
                          <Descriptions.Item label="9. BALANCE TO FINISH, PLUS RETAINAGE">
                            {formatPrice(balanceToFinish)}
                          </Descriptions.Item>
                        </Descriptions>
                      </Space>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24} style={{ paddingTop: '30px' }}>
                      <Descriptions title="PROJECT COST BREAKDOWN SHEET" layout="horizontal" column={1} />
                      {calculateTotals(values.items)}
                      <Table
                        locale={{ emptyText: 'No Items' }}
                        key="proposalClientItem"
                        rowKey="id"
                        columns={tableColumns}
                        dataSource={values.items.filter(
                          item =>
                            item.proposalClientItem &&
                            !item.generalConditions &&
                            !item.overheadAndProfit &&
                            !item.proposalOtherItem
                        )}
                        pagination={false}
                        bordered
                        size="small"
                        showExpandColumn={false}
                      />
                      {values.items.filter(item => item.proposalOtherItem).length > 0 && (
                        <Table
                          title={() => <Text>ADD ALT'S</Text>}
                          locale={{ emptyText: 'No Items' }}
                          key="proposalOtherItem"
                          rowKey="id"
                          showHeader={false}
                          columns={tableColumnsOtherItems}
                          dataSource={values.items.filter(item => item.proposalOtherItem)}
                          pagination={false}
                          bordered
                          size="small"
                          showExpandColumn={false}
                          style={{ marginTop: '-8px' }}
                        />
                      )}
                      {values.items.filter(item => item.generalConditions || item.overheadAndProfit).length > 0 && (
                        <Table
                          title={() => <Text>SUMMARY</Text>}
                          locale={{ emptyText: 'No Items' }}
                          key="proposalSummaryItems"
                          rowKey="id"
                          showHeader={false}
                          columns={tableColumnsSummary}
                          dataSource={values.items.filter(item => item.generalConditions || item.overheadAndProfit)}
                          pagination={false}
                          bordered
                          size="small"
                          showExpandColumn={false}
                          style={{ marginTop: '-8px' }}
                        />
                      )}
                      {values.items.filter(item => item.changeOrderClient).length > 0 && (
                        <Table
                          title={() => <Text>CHANGE ORDER</Text>}
                          locale={{ emptyText: 'No Change Orders Items' }}
                          key="ChangeOrderItems"
                          rowKey="id"
                          showHeader={false}
                          columns={tableChangeOrderItems}
                          dataSource={values.items.filter(item => item.changeOrderClient)}
                          pagination={false}
                          bordered
                          size="small"
                          showExpandColumn={false}
                          style={{ marginTop: '-8px' }}
                          // summary={() => {
                          //   return (
                          //     <>
                          //       <Table.Summary.Row style={{ backgroundColor: '#fffff0' }}>
                          //         <Table.Summary.Cell index={0} colSpan={2} />
                          //         <Table.Summary.Cell index={1} align="center">
                          //           <Text strong>{formatPrice(totalScheduleValue)}</Text>
                          //         </Table.Summary.Cell>
                          //         <Table.Summary.Cell index={2} align="center">
                          //           <Text strong>{formatPrice(totalPreviousApplication)}</Text>
                          //         </Table.Summary.Cell>
                          //         <Table.Summary.Cell index={3} align="center">
                          //           <Text strong>{formatPrice(totalWorkInPlace)}</Text>
                          //         </Table.Summary.Cell>
                          //         <Table.Summary.Cell index={4} align="center">
                          //           <Text strong>{formatPrice(totalStoredMaterials)}</Text>
                          //         </Table.Summary.Cell>
                          //         <Table.Summary.Cell index={5} align="center">
                          //           <Text strong>{formatPrice(totalToDate)}</Text>
                          //         </Table.Summary.Cell>
                          //         <Table.Summary.Cell index={6} align="center">
                          //           <Text strong>{formatPercent(totalPercentageComplete)}</Text>
                          //         </Table.Summary.Cell>
                          //         <Table.Summary.Cell index={7} align="center">
                          //           <Text strong>{formatPrice(totalBalanceToFinish)}</Text>
                          //         </Table.Summary.Cell>
                          //         <Table.Summary.Cell index={8} align="center">
                          //           <Text strong>{formatPrice(totalRetainage)}</Text>
                          //         </Table.Summary.Cell>
                          //       </Table.Summary.Row>
                          //     </>
                          //   );
                          // }}
                        />
                      )}
                      <div className="ant-table-wrapper" style={{ marginTop: '-8px' }}>
                        <div className="ant-table ant-table-middle ant-table-bordered ant-table-empty">
                          <div className="ant-table-container">
                            <div className="ant-table-content">
                              <table style={{ tableLayout: 'fixed' }} className="">
                                <colgroup>
                                  <col style={{ width: '50px' }} />
                                  <col style={{ width: '250px' }} />
                                  <col />
                                  <col />
                                  <col />
                                  <col />
                                  <col />
                                  <col />
                                  <col />
                                  <col />
                                  <col style={{ width: '70px' }} />
                                </colgroup>
                                <tfoot className="ant-table-summary">
                                  <tr style={{ backgroundColor: '#fffff0' }}>
                                    <td className="ant-table-cell ant-table-cell-ellipsis" colSpan="2" />
                                    <td className="ant-table-cell ant-table-cell-ellipsis" style={{ textAlign: 'center' }}>
                                      <span className="ant-typography">
                                        <strong>{formatPrice(totalScheduleValue)}</strong>
                                      </span>
                                    </td>
                                    <td className="ant-table-cell ant-table-cell-ellipsis" style={{ textAlign: 'center' }}>
                                      <span className="ant-typography">
                                        <strong>{formatPrice(totalPreviousApplication)}</strong>
                                      </span>
                                    </td>
                                    <td className="ant-table-cell ant-table-cell-ellipsis" style={{ textAlign: 'center' }}>
                                      <span className="ant-typography">
                                        <strong>{formatPrice(totalWorkInPlace)}</strong>
                                      </span>
                                    </td>
                                    <td className="ant-table-cell ant-table-cell-ellipsis" style={{ textAlign: 'center' }}>
                                      <span className="ant-typography">
                                        <strong>{formatPrice(totalStoredMaterials)}</strong>
                                      </span>
                                    </td>
                                    <td className="ant-table-cell ant-table-cell-ellipsis" style={{ textAlign: 'center' }}>
                                      <span className="ant-typography">
                                        <strong>{formatPrice(totalToDate)}</strong>
                                      </span>
                                    </td>
                                    <td className="ant-table-cell ant-table-cell-ellipsis" style={{ textAlign: 'center' }}>
                                      <span className="ant-typography">
                                        <strong>{formatPercent(totalPercentageComplete)}</strong>
                                      </span>
                                    </td>
                                    <td className="ant-table-cell ant-table-cell-ellipsis" style={{ textAlign: 'center' }}>
                                      <span className="ant-typography">
                                        <strong>{formatPrice(totalBalanceToFinish)}</strong>
                                      </span>
                                    </td>
                                    <td className="ant-table-cell ant-table-cell-ellipsis" style={{ textAlign: 'center' }}>
                                      <span className="ant-typography">
                                        <strong>{formatPrice(totalRetainage)}</strong>
                                      </span>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Modal>
            <FormItem
              selectedItem={selectedItem}
              visible={showModal}
              onClose={() => setShowModal(false)}
              onUpdateItem={
                // update item in values.items array with new values
                updateItemValues => {
                  const newItems = values.items.map(item => {
                    if (item.id === updateItemValues.id) {
                      return updateItemValues;
                    }
                    return item;
                  });
                  setFieldValue('items', newItems);
                  setShowModal(false);
                }
              }
            />
          </>
        )}
      </Formik>
    </>
  );
}
