import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Button, Input, message } from 'antd';
import moment from 'moment';
import './style.css';

import { cashFlow } from '~/services/hooks/quickbooksReports';
import api from '~/services/api';

const { RangePicker } = DatePicker;

export default function CashFlowReport() {
  const [dateRange, setDateRange] = useState([
    moment().startOf('year'),
    moment().endOf('year')
  ]);
  const [customerName, setCustomerName] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchCustomerIdByName(name) {
    try {
      const { data } = await api.get(`/api/quickbooks/customer?name=${encodeURIComponent(name)}`);
      const parsed = JSON.parse(data);
      return parsed?.QueryResponse?.Customer?.[0]?.Id ?? null;
    } catch (err) {
      message.error('Erro ao buscar o cliente.');
      return null;
    }
  }

  async function fetchData(startDate, endDate) {
    setLoading(true);
    let customerId = null;

    if (customerName.trim()) {
      customerId = await fetchCustomerIdByName(customerName);
      if (!customerId) {
        message.warning('Cliente não encontrado.');
        setLoading(false);
        return;
      }
    }

    const reportData = await cashFlow({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      customerId
    });

    if (reportData?.Rows?.Row) {
      setDataSource(parseCashFlowData(reportData));
    } else {
      console.warn('Formato inesperado dos dados:', reportData);
      setDataSource([]);
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchData(dateRange[0], dateRange[1]);
  }, []);

  const columns = [
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      render: (text, record) => {
        const indent = record.level * 20;
        return <span style={{ paddingLeft: indent }}>{text}</span>;
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: text => {
        if (text === '') return '';
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(Number(text));
        return formatted;
      },
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 16, display: 'flex', gap: 8 }}>
        <RangePicker
          value={dateRange}
          onChange={(dates) => setDateRange(dates)}
          format="YYYY-MM-DD"
        />
        <Input
          placeholder="Nome do cliente/projeto"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          style={{ width: 250 }}
        />
        <Button 
          type="primary" 
          onClick={() => fetchData(dateRange[0], dateRange[1])}
          loading={loading}
        >
          Buscar Relatório
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        rowClassName={record => (record.isSummary ? 'summary-row' : '')}
      />
    </div>
  );
}

// Função para formatar os dados da API
function parseCashFlowData(data) {
  if (!data?.Rows?.Row) return [];

  const rows = data.Rows.Row;
  const parsedRows = [];

  function traverseRows(rowsArray, level = 0) {
    if (!Array.isArray(rowsArray)) return;

    rowsArray.forEach(row => {
      if (row.Header) {
        const accountName = row.Header.ColData[0].value;
        parsedRows.push({
          key: `${accountName}-${level}-${Math.random()}`,
          account: accountName,
          total: '',
          level,
          isSummary: false,
        });
      }

      if (row.ColData) {
        const accountName = row.ColData[0].value;
        const totalValue = row.ColData[1].value;
        parsedRows.push({
          key: `${accountName}-${level}-${Math.random()}`,
          account: accountName,
          total: totalValue,
          level,
          isSummary: row.type === 'Data',
        });
      }

      if (row.Rows) {
        traverseRows(row.Rows.Row, level + 1);
      }

      if (row.Summary) {
        const summaryName = row.Summary.ColData[0].value;
        const totalValue = row.Summary.ColData[1].value;
        parsedRows.push({
          key: `${summaryName}-${level}-${Math.random()}`,
          account: summaryName,
          total: totalValue,
          level,
          isSummary: true,
        });
      }
    });
  }

  traverseRows(rows);
  return parsedRows;
}
