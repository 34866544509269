import React, { useEffect, useState } from 'react';
import DefaultLayout from '~/pages/_layouts/full';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Table, Input, Select, DatePicker, Button, Modal, Descriptions } from 'antd';
import { BreadcrumbCustom } from '~/styles/global';
import { findAllRevisions } from '~/services/hooks/audit';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function AuditLog() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // State for filters
  const [filters, setFilters] = useState({
    entityClass: 'User', // Default selected class
    user: '',
    revtype: '',
    dateInit: null,
    dateEnd: null,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const entityClasses = [
    { label: 'Areas', value: 'Area' },
    { label: 'Certificates', value: 'Insurence' },
    { label: 'Costs', value: 'Cost' },
    { label: 'Customers', value: 'Customer' },
    { label: 'Estimate Items', value: 'EstimateDescription' },
    { label: 'Estimates', value: 'Estimate' },
    { label: 'Hotels', value: 'Hotel' },
    { label: 'PayApps', value: 'PayAppClient' },
    { label: 'Projects', value: 'Project' },
    { label: 'Purchase Orders', value: 'PurchaseOrder' },
    { label: 'Subcontractors', value: 'SubVendor' },
    { label: 'Subcontractors Trades', value: 'LaborType' },
    { label: 'Users', value: 'User' },
  ];

  const fetchRevisions = async () => {
    setLoading(true);
    const filterList = [];

    if (filters.user) {
      filterList.push({ field: 'user', value: filters.user });
    }
    if (filters.revtype) {
      filterList.push({ field: 'revtype', value: filters.revtype });
    }
    if (filters.dateInit && filters.dateEnd) {
      filterList.push({ field: 'dateInit', value: filters.dateInit });
      filterList.push({ field: 'dateEnd', value: filters.dateEnd });
    }

    try {
      const response = await findAllRevisions(filters.entityClass, filterList);

      const formattedData = response.map(item => {
        let actionLabel = '';
        switch (item.revisionType) {
          case 'ADD':
            actionLabel = 'Insert';
            break;
          case 'MOD':
            actionLabel = 'Update';
            break;
          case 'DEL':
            actionLabel = 'Delete';
            break;
          default:
            actionLabel = item.revisionType;
        }

        return {
          key: item.customRevision.id,
          dateTime: moment(item.customRevision.timestamp).format('YYYY-MM-DD HH:mm:ss'),
          user: item.customRevision.username,
          action: actionLabel,
          entityType: filters.entityClass,
          entityId: item.id,
          details: item,
        };
      });
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching revisions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRevisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({ ...prev, [field]: value }));
  };

  const handleDateRangeChange = (dates, dateStrings) => {
    handleFilterChange('dateInit', dateStrings[0]);
    handleFilterChange('dateEnd', dateStrings[1]);
  };

  // Function to open modal and set modal data
  const showModal = record => {
    setModalData({ ...record.details, entityType: record.entityType });
    setIsModalVisible(true);
  };

  // Function to close modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setModalData(null);
  };

  // Function to generate Descriptions items based on entity class
  const renderModalContent = () => {
    if (!modalData) return null;
    const { entityType } = modalData;
    const data = modalData;

    switch (entityType) {
      case 'User':
        return (
          <Descriptions column={1} bordered>
            <Descriptions.Item label="ID">{data.id}</Descriptions.Item>
            <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
            <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
            <Descriptions.Item label="Status">{data.status}</Descriptions.Item>
            {/* Add other fields as needed */}
          </Descriptions>
        );
      case 'Project':
        return (
          <Descriptions column={1} bordered>
            <Descriptions.Item label="ID">{data.id}</Descriptions.Item>
            <Descriptions.Item label="Type">{data.typeDescription}</Descriptions.Item>
            <Descriptions.Item label="Description">{data.description}</Descriptions.Item>
            {/* Add other fields as needed */}
          </Descriptions>
        );
      default:
        return (
          <Descriptions column={1} bordered>
            {Object.entries(data).map(([key, value]) => (
              <Descriptions.Item label={key} key={key}>
                {typeof value === 'object' ? JSON.stringify(value) : value}
              </Descriptions.Item>
            ))}
          </Descriptions>
        );
    }
  };

  const columns = [
    {
      title: 'Date/Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      sorter: (a, b) => moment(a.dateTime).unix() - moment(b.dateTime).unix(),
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.user.localeCompare(b.user),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      filters: [
        { text: 'Insert', value: 'Insert' },
        { text: 'Update', value: 'Update' },
        { text: 'Delete', value: 'Delete' },
      ],
      onFilter: (value, record) => record.action === value,
    },
    // {
    //   title: 'Entity Type',
    //   dataIndex: 'entityType',
    //   key: 'entityType',
    // },
    {
      title: 'Entity ID',
      dataIndex: 'entityId',
      key: 'entityId',
    },
    {
      title: 'Details',
      key: 'details',
      render: (_, record) => (
        <Button type="link" onClick={() => showModal(record)}>
          + Details
        </Button>
      ),
    },
  ];

  const breadcrumb = (
    <BreadcrumbCustom>
      <Breadcrumb.Item href="/">{t('menus:home')}</Breadcrumb.Item>
      <Breadcrumb.Item>{t('menus:auditLog')}</Breadcrumb.Item>
    </BreadcrumbCustom>
  );

  return (
    <DefaultLayout breadcrumb={breadcrumb}>
      <Box>
        <PageTitle title={t('menus:auditLog')} />
        <div style={{ marginBottom: 16, display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
          <Select
            placeholder="Select Class"
            style={{ width: 200 }}
            onChange={value => handleFilterChange('entityClass', value)}
            defaultValue={filters.entityClass}
          >
            {entityClasses.map(entity => (
              <Option key={entity.value} value={entity.value}>
                {entity.label}
              </Option>
            ))}
          </Select>
          <Input
            placeholder="User Email"
            style={{ width: 200 }}
            onChange={e => handleFilterChange('user', e.target.value)}
          />
          <Select
            placeholder="Action Type"
            style={{ width: 120 }}
            onChange={value => handleFilterChange('revtype', value)}
            allowClear
          >
            <Option value="ADD">Insert</Option>
            <Option value="MOD">Update</Option>
            <Option value="DEL">Delete</Option>
          </Select>
          <RangePicker
            format="YYYY-MM-DD"
            onChange={handleDateRangeChange}
          />
          <Button type="primary" onClick={fetchRevisions}>
            Search
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey="key"
          pagination={{ pageSize: 10 }}
        />
        {/* Modal Details */}
        <Modal
          title="Details"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
          ]}
        >
          {renderModalContent()}
        </Modal>
      </Box>
    </DefaultLayout>
  );
}
