import React from 'react';
import 'antd/dist/antd.css';
import { DeleteOutlined, EditOutlined, DownloadOutlined, MessageOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Popconfirm, Space, Comment } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import { TableActions } from '~/components/Table';
import moment from 'moment';
import UserInfo from '~/components/UserInfo';
import UsersInfo from '~/components/UsersInfo';

import './styles.css';

function ItemRFI({ userLogged, record, tableData, handleDelete, handleEdit, handleReply, downloadFile }) {
  const { t } = useTranslation();
  if (record.replied === true) {
    record.replies = tableData?.filter(i => i.reply?.id === record.id);
  }

  const nestedItemRFI = (record.replies || []).map(comment => {
    return (
      <ItemRFI
        key={comment.id}
        userLogged={userLogged}
        record={comment}
        tableData={tableData}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleReply={handleReply}
        downloadFile={downloadFile}
        recipientName={record.sentBy.name}
      />
    );
  });

  return (
    <Comment
      style={{ backgroundColor: '#ffffff', borderRadius: '10px', paddingLeft: '10px', marginBottom: '10px' }}
      actions={[
        <TableActions>
          <Button onClick={async () => await downloadFile(record.arquive)} title={t('messages:download')}>
            <DownloadOutlined />
          </Button>
          {userLogged?.id === record.sentBy.id && (
            <Button onClick={() => handleEdit(record)} title={t('messages:edit')}>
              <EditOutlined />
            </Button>
          )}
          {userLogged?.id === record.sentBy.id && (
            <Popconfirm
              icon={false}
              title={t('messages:confirmDelete')}
              onConfirm={() => handleDelete(record.id)}
              okText={t('messages:yes')}
              cancelText={t('messages:no')}
            >
              <Button title={t('messages:delete')}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
          {userLogged?.id !== record.sentBy.id && (
            <Button onClick={() => handleReply(record)} title={t('messages:reply')}>
              <MessageOutlined />
            </Button>
          )}
        </TableActions>,
      ]}
      author={
        <Space>
          <UserInfo user={record.sentBy} />
          <ArrowRightOutlined />
          {record.users.length === 0 ? <>{record?.reply?.sentBy?.name || 'not assigned'}</> : <UsersInfo users={record.users} />}
        </Space>
      }
      content={
        <>
          <p style={{ color: 'gray', fontSize: '12px', marginBottom: '0px' }}>
            {moment(record.audit.createdDate).format('YYYY/MM/DD HH:MM:ss')}
          </p>
          {record.description}
        </>
      }
    >
      {nestedItemRFI}
    </Comment>
  );
}

export default ItemRFI;
